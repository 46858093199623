import {Trans} from 'react-i18next';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import DonutSmallIcon from '@mui/icons-material/DonutSmall';
import DashboardIcon from '@mui/icons-material/Dashboard';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import InboxIcon from '@mui/icons-material/Inbox';
import EditNoteIcon from '@mui/icons-material/EditNote';
import PolylineOutlinedIcon from '@mui/icons-material/PolylineOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import DownloadingIcon from '@mui/icons-material/Downloading';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import AssignmentReturnedIcon from '@mui/icons-material/AssignmentReturned';
import AssessmentIcon from '@mui/icons-material/Assessment';
import StyleIcon from '@mui/icons-material/Style';

// base routes
export const ROUTE_ROOT = '/';
export const ROUTE_SIGN_IN = '/auth/signin';
export const ROUTE_FORGOT_PASSWORD = '/auth/forgot-password';
export const ROUTE_RESET_PASSWORD = '/auth/reset-password';
export const ROUTE_UN_AUTHORIZED = '/unauthorized';

// dashboard
export const ROUTE_APP_DASHBOARD = '/dashboard';

// plays routes
export const ROUTE_PLAYS = '/plays';
export const ROUTE_PLAYS_EXPLORE = `${ROUTE_PLAYS}/explore`;
export const ROUTE_PLAYS_CREATE = `${ROUTE_PLAYS}/create`;

// evidence routes
export const ROUTE_EVIDENCE = '/evidence';
export const ROUTE_EVIDENCE_EXPLORE = `${ROUTE_EVIDENCE}/explore`;
export const ROUTE_EVIDENCE_MANAGE = `${ROUTE_EVIDENCE}/manage`;

// inbox routes
export const ROUTE_INBOX = '/inbox';
export const ROUTE_INBOX_EXPLORE = `${ROUTE_INBOX}/explore`;
export const ROUTE_INBOX_SEARCH_PHRASES = `${ROUTE_INBOX}/search-phrases`;
export const ROUTE_INBOX_MODEL_PERFORMANCE = `${ROUTE_INBOX}/performance`;
export const ROUTE_INBOX_MODEL_PERFORMANCE_OVERVIEW = `${ROUTE_INBOX}/performance/models/:modelId`;

// settings routes
export const ROUTE_SETTINGS = '/settings';
export const ROUTE_PIPELINES = `/pipelines`;
export const ROUTE_PIPELINES_EXTRACTIONS = `${ROUTE_PIPELINES}/extractions`;
export const ROUTE_PIPELINES_DOWNLOAD_SESSIONS = `${ROUTE_PIPELINES}/downloads`;
export const ROUTE_PIPELINES_CLASSIFICATION_SESSIONS = `${ROUTE_PIPELINES}/classifications`;
export const ROUTE_PIPELINES_MODEL_TRAINING_SESSIONS = `${ROUTE_PIPELINES}/model-trainings`;
export const pathToIndex = {
    [ROUTE_APP_DASHBOARD]: 0,
    [ROUTE_PLAYS_EXPLORE]: 1,
    [ROUTE_EVIDENCE_EXPLORE]: 2,
    [ROUTE_EVIDENCE_MANAGE]: 3,
    [ROUTE_INBOX_EXPLORE]: 4,
    [ROUTE_INBOX_MODEL_PERFORMANCE]: 5,
    [ROUTE_SETTINGS]: 6,
    [ROUTE_INBOX_SEARCH_PHRASES]: 7,
    [ROUTE_PIPELINES]: 8,
    [ROUTE_PIPELINES_EXTRACTIONS]: 9,
    [ROUTE_PIPELINES_DOWNLOAD_SESSIONS]: 10,
    [ROUTE_PIPELINES_CLASSIFICATION_SESSIONS]: 11,
    [ROUTE_PIPELINES_MODEL_TRAINING_SESSIONS]:12
};

export const appRoutesGrouped = [
    {
        group: {
            id: 0,
            name: '',
        },
        items: [
            {
                id: pathToIndex[ROUTE_APP_DASHBOARD],
                path: ROUTE_APP_DASHBOARD,
                displayName: <Trans i18nKey="dashboard"/>,
                icon: <DashboardIcon/>,
            },
        ],
    },
    {
        group: {
            id: 1,
            name: <Trans i18nKey="inbox"/>,
        },
        items: [
            {
                id: pathToIndex[ROUTE_INBOX_EXPLORE],
                path: ROUTE_INBOX_EXPLORE,
                displayName: <Trans i18nKey="inbox.explore"/>,
                icon: <InboxIcon/>,
            },
            {
                id: pathToIndex[ROUTE_INBOX_SEARCH_PHRASES],
                path: ROUTE_INBOX_SEARCH_PHRASES,
                displayName: <Trans i18nKey="inbox.searchPhrases"/>,
                icon: <ManageSearchIcon/>,
            },
            {
                id: pathToIndex[ROUTE_INBOX_MODEL_PERFORMANCE],
                path: ROUTE_INBOX_MODEL_PERFORMANCE,
                displayName: <Trans i18nKey="inbox.modelPerformance"/>,
                icon: <AssessmentIcon/>,
            },
        ],
    },
    {
        group: {
            id: 2,
            name: <Trans i18nKey="evidence"/>,
        },
        items: [
            {
                id: pathToIndex[ROUTE_EVIDENCE_EXPLORE],
                path: ROUTE_EVIDENCE_EXPLORE,
                displayName: <Trans i18nKey="evidence.explore"/>,
                icon: <TroubleshootIcon/>,
            },
        ],
    },
    // {
    //     group: {
    //         id: 3,
    //         name: <Trans i18nKey="plays"/>,
    //     },
    //     items: [
    //         {
    //             id: pathToIndex[ROUTE_PLAYS_EXPLORE],
    //             path: ROUTE_PLAYS_EXPLORE,
    //             displayName: <Trans i18nKey="plays.explore"/>,
    //             icon: <LocalLibraryIcon/>,
    //         }
    //     ]
    // },
    {
        group: {
            id: 3,
            name: <Trans i18nKey="pipelines"/>,
        },
        items: [
            {
                id: pathToIndex[ROUTE_PIPELINES_DOWNLOAD_SESSIONS],
                path: ROUTE_PIPELINES_DOWNLOAD_SESSIONS,
                displayName: <Trans i18nKey="inbox.downloads"/>,
                icon: <DownloadingIcon/>,
            },
            {
                id: pathToIndex[ROUTE_PIPELINES_EXTRACTIONS],
                path: ROUTE_PIPELINES_EXTRACTIONS,
                displayName: <Trans i18nKey="evidence.extractions"/>,
                icon: <AssignmentReturnedIcon/>,
            },
            {
                id: pathToIndex[ROUTE_PIPELINES_CLASSIFICATION_SESSIONS],
                path: ROUTE_PIPELINES_CLASSIFICATION_SESSIONS,
                displayName: <Trans i18nKey="pipelines.classifications"/>,
                icon: <StyleIcon/>,
            },
            {
                id: pathToIndex[ROUTE_PIPELINES_MODEL_TRAINING_SESSIONS],
                path: ROUTE_PIPELINES_MODEL_TRAINING_SESSIONS,
                displayName: <Trans i18nKey="pipelines.modelTrainings"/>,
                icon: <ModelTrainingIcon/>,
            },
        ],
    },
    {
        group: {
            id: 5,
            name: <Trans i18nKey="settings"/>,
        },
        items: [
            {
                id: pathToIndex[ROUTE_SETTINGS],
                path: ROUTE_SETTINGS,
                displayName: <Trans i18nKey="settings"/>,
                icon: <SettingsOutlinedIcon/>,
            },
        ],
    },
];
