import {useTranslation} from "react-i18next";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import PendingOutlinedIcon from "@mui/icons-material/PendingOutlined";
import PlagiarismOutlinedIcon from "@mui/icons-material/PlagiarismOutlined";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CustomTooltip from "../../../../components/elements/CustomTooltip";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";

const ExtractionItem = ({evidence, onClick, selected, width = 12}) => {
    const {t} = useTranslation();

    const statusIcon = (status) => {
        switch (status) {
            case 'EXTRACTION_SUCCESS':
                return <CheckCircleIcon sx={{color: '#00c360'}}/>;
            case 'EXTRACTION_FAILED':
                return <WarningAmberIcon sx={{color: '#df812e'}}/>;
            case 'EXTRACTION_PARTIAL':
                return <PendingOutlinedIcon sx={{color: '#EED437'}}/>;
            case 'NOT_EXTRACTED':
                return <PlagiarismOutlinedIcon sx={{color: '#0894d3'}}/>;
            default:
                return <></>;
        }
    };

    const getStatus = (evidence) => {
        let successCount = 0
        let partialCount = 0
        let errorCount = 0

        const keys = ['research_objective', 'study_design', 'treatment_used', 'population_characteristics', 'population', 'outcome_metrics']

        if (evidence && evidence.extracted_evidences && typeof evidence.extracted_evidences === 'object') {
            const hasAllKeys = keys.every(item => evidence.extracted_evidences.hasOwnProperty(item));
            if (hasAllKeys) {
                successCount = successCount + 1
            } else {
                const hasAnyKeys = keys.some(item => evidence.extracted_evidences.hasOwnProperty(item));
                if (hasAnyKeys) {
                    partialCount = partialCount + 1
                } else {
                    errorCount = errorCount + 1
                }
            }
        }

        if (successCount > 0) {
            return <CustomTooltip title={statusTooltip('EXTRACTION_SUCCESS')}>
                {statusIcon('EXTRACTION_SUCCESS')}
            </CustomTooltip>
        } else if (partialCount > 0) {
            return <CustomTooltip title={statusTooltip('EXTRACTION_PARTIAL')}>
                {statusIcon('EXTRACTION_PARTIAL')}
            </CustomTooltip>
        } else if (errorCount > 0) {
            return <CustomTooltip title={statusTooltip('EXTRACTION_FAILED')}>
                {statusIcon('EXTRACTION_FAILED')}
            </CustomTooltip>
        } else {
            return <CustomTooltip title={statusTooltip('NOT_EXTRACTED')}>
                {statusIcon('NOT_EXTRACTED')}
            </CustomTooltip>
        }
    }

    const getLabel = (source) => {
        switch (source) {
            case 'GPT':
                return 'GPT';
            case 'CHAT_GPT':
                return 'GPT';
            case 'CHAT_GPT_KOR':
                return 'GPT KOR';
            case 'MANUAL':
                return 'Manual';
            case 'CURATED':
                return 'Curated';
            default:
                return '';
        }
    }

    const statusTooltip = (status) => {
        switch (status) {
            case 'EXTRACTION_SUCCESS':
                return t('Extraction Success');
            case 'EXTRACTION_FAILED':
                return t('Extraction Failed');
            case 'EXTRACTION_PARTIAL':
                return t('Extraction Partial');
            case 'NOT_EXTRACTED':
                return t('Extraction Pending');
            default:
                return '';
        }
    };

    return <ExtractionChip width={width} selected={selected} onClick={onClick}>
        <span style={{paddingRight: '0.5em'}}>{getStatus(evidence)}</span>
        <Typography>
            {getLabel(evidence.extraction_type)}
        </Typography>
    </ExtractionChip>
}

const ExtractionChip = ({width = 12, selected, children, onClick}) => {
    return <Grid container item sx={{width:'fit-content',minWidth:'128px'}}>
        <Grid item xs={12} sx={{
            border: '1px solid #797979',
            borderRadius: '4px',
            height: 'fit-content',
            padding: '0.5em',
            display: 'flex',
            cursor: 'pointer', ...(selected && {
                backgroundColor: '#f0f8ff'
            })
        }} onClick={onClick}>
            {children}
        </Grid>
    </Grid>
}

const ExtractionPdf = ({width = 12, selected, onClick}) => {
    return <ExtractionChip width={width} selected={selected} onClick={onClick}>
        <span style={{paddingRight: '0.5em'}}>
            <PictureAsPdfIcon sx={{color: '#0894d3'}}/>
        </span>
        <Typography>
            Pdf
        </Typography>
    </ExtractionChip>
}

export {ExtractionItem, ExtractionChip,ExtractionPdf};