import React, {useState} from 'react';
import Box from '@mui/material/Box';
import styled from '@mui/system/styled';
import MuiAccordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Grid from '@mui/material/Grid';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import RateReviewIcon from '@mui/icons-material/RateReview';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import PlagiarismOutlinedIcon from '@mui/icons-material/PlagiarismOutlined';
import AddIcon from '@mui/icons-material/Add';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Divider from '@mui/material/Divider';
import {Checkbox} from '@mui/material';

import LabeledChip from 'components/elements/LabeledChip';
import useHover from 'hooks/useHover';
import CardHeaderColumn from 'components/elements/CardHeader';
import CustomTooltip from 'components/elements/CustomTooltip';
import usePaperUpdateValidated from 'hooks/papers/usePaperUpdateValidated';

import CreateEvidenceDialog from './createEvidence/create-evidence-dialog';
import ReviewEvidenceDialog from './reviewEvidence/review-evidence-dialog';
import ActionMenu from './ActionMenu';

const CardRoot = styled(Box)(() => ({
    padding: '2px 0px 6px 0px',
}));

const Accordion = styled(MuiAccordion)(({theme, active = false}) => ({
    border: '0.1em solid #E9E9E9',
    boxShadow: 'none',
    ...(active && {
        background: 'aliceblue',
    }),
}));

const EvidenceGridCard = ({
                              style,
                              data,
                              index,
                              handleClick,
                              selected = false,
                              handleRefetch,
                              handleSelect,
                              selectedEvidences = [],
                              selectAll = false
                          }) => {
        const {t} = useTranslation();
        const [hoverRef] = useHover();
        let [searchParams] = useSearchParams();
        const [anchorEl, setAnchorEl] = React.useState(null);
        const open = Boolean(anchorEl);
        const [expand, setExpand] = useState(false);

        const [paperAdding, setPaperAdding] = useState(false);
        const [reviewing, setReviewing] = useState(false);

        const {mutateAsync, status} = usePaperUpdateValidated();

        const handleSubmit = (value) => {
            const req = {
                "relevant_paper_manually_validated": value
            }
            mutateAsync({req, paperId: data.paper_id}).then(() => {
                handleMoreClose()
                handleRefetch()
            });
        }

        const handleAdd = (paper) => {
            setPaperAdding(paper)
        }

        const handleAddPaperClose = () => {
            setPaperAdding(null)
        }

        const handleReview = (e) => {
            e.stopPropagation()
            setReviewing(true)
        }

        const handleReviewClose = () => {
            setReviewing(false)
            handleRefetch()
        }

        const onToggleExpandRow = () => {
            setExpand((prev) => !prev);
        };

        const isActive = () => {
            return data['run_id'] === searchParams.get('pipeline_id')
        }

        const statusIcon = (status) => {
            switch (status) {
                case 'SUCCESS':
                    return <CheckCircleIcon sx={{color: '#00c360'}}/>;
                case 'FAILED':
                    return <WarningAmberIcon sx={{color: '#df812e'}}/>;
                case 'PARTIAL':
                    return <AutoModeIcon sx={{color: '#EED437'}}/>;
                case 'NOT_EXTRACTED':
                    return <PlagiarismOutlinedIcon sx={{color: '#0894d3'}}/>;
                default:
                    return <></>;
            }
        };

        const getEvidences = (evidences) => {
            const list = []
            if (evidences && evidences.length > 0) {
                evidences.forEach(provider => {
                    if (provider && !list.includes(provider.extraction_type)) {
                        list.push(provider.extraction_type)
                    }
                })

                return list.map(evidence => {
                    return <Grid item><LabeledChip label={t(getLabel(evidence))}
                                                   backgroundColor={getColor(evidence)}/></Grid>
                })

            } else {
                return <Grid item><LabeledChip label={'Not Extracted'}
                                               backgroundColor={colors[2]}/></Grid>
            }
        }

        const colors = ['#daf7ca', '#efefcb', '#c8edfd']

        const getColor = (source) => {
            switch (source) {
                case 'GPT':
                    return colors[0];
                case 'CHAT_GPT':
                    return colors[0];
                case 'CURATED':
                    return colors[2];
                case 'MANUAL':
                    return colors[1];
                default:
                    return '';
            }
        }

        const getLabel = (source) => {
            switch (source) {
                case 'GPT':
                    return 'GPT';
                case 'CHAT_GPT':
                    return 'GPT';
                case 'CHAT_GPT_KOR':
                    return 'GPT KOR';
                case 'MANUAL':
                    return 'Manual';
                case 'CURATED':
                    return 'Curated';
                default:
                    return '';
            }
        }

        const statusTooltip = (status) => {
            switch (status) {
                case 'SUCCESS':
                    return t('Extraction Success');
                case 'FAILED':
                    return t('Extraction Failed');
                case 'PARTIAL':
                    return t('Extraction Partial');
                case 'NOT_EXTRACTED':
                    return t('Marked for Extraction');
                default:
                    return '';
            }
        };

        const handleMore = (event) => {
            event.stopPropagation();
            setAnchorEl(event.currentTarget);
        };

        const handleMoreClose = () => {
            setAnchorEl(null);
        };

        return (
            <CardRoot style={style} ref={hoverRef}>
                <Accordion expanded={expand} TransitionProps={{unmountOnExit: true}} active={isActive()}>
                    <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{
                            '&.MuiAccordionSummary-root': {boxShadow: '1px 2px 13px -10px #888'},
                            '&.MuiAccordionSummary-root.Mui-expanded': {minHeight: 'unset'},
                            div: {
                                '&.MuiAccordionSummary-content.Mui-expanded': {margin: '12px 0'},
                            },
                            '&:hover': {
                                cursor: 'pointer'
                            },
                            ...(selected && {
                                background: 'aliceblue',
                            }),
                        }}

                    >
                        <Grid container>
                            <Grid item xs={12} container justifyContent="space-between" flexWrap='nowrap' spacing={1}
                                  onClick={() => handleClick(data)}>
                                <Grid item container xs={0.6} alignItems='center' justifyContent='center'>
                                    <Grid item container alignItems='center' justifyContent='center'>
                                        <CustomTooltip title={statusTooltip(data['paper_evidence_status'])}>
                                            {statusIcon(data['paper_evidence_status'])}
                                        </CustomTooltip>
                                        <Grid item>
                                            <Checkbox size="small"
                                                      checked={selectedEvidences.includes(data['paper_id']) || selectAll}
                                                      disabled={selectAll}
                                                      onClick={e => e.stopPropagation()}
                                                      onChange={() => handleSelect(data['paper_id'])}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item container xs={0.4} alignItems='center'>
                                    <Grid item xs={12} sx={{height: '80%'}}>
                                        <Divider sx={{
                                            '&.MuiDivider-root': {
                                                borderWidth: '1px',
                                                borderColor: '#a7a7a7',
                                                width: 'fit-content'
                                            }
                                        }}
                                                 orientation='vertical'/>
                                    </Grid>
                                </Grid>
                                <CardHeaderColumn
                                    spacing={11}
                                    headerName={t('inbox.paperName')}
                                    headerTitle={<Grid
                                        item
                                        container
                                        alignItems="center"
                                        spacing={1}
                                    >
                                        <Grid item>{data['paper_name']}</Grid>
                                        <Grid item container spacing={0.5}>
                                            {getEvidences(data['extracted_evidences'])}
                                        </Grid>
                                    </Grid>}
                                />
                            </Grid>
                            <Grid item container spacing={0.5} justifyContent={"flex-end"} alignItems="center" xs={12}>
                                <Grid item><IconButton onClick={handleReview}><RateReviewIcon
                                    fontSize={'small'}/></IconButton></Grid>
                                <Grid item><IconButton onClick={(e) => {
                                    e.stopPropagation()
                                    handleAdd(data)
                                }}><AddIcon
                                    fontSize={'small'}/></IconButton></Grid>
                                <Grid item>
                                    <IconButton onClick={handleMore}><MoreVertIcon/></IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                </Accordion>
                {paperAdding &&
                    <CreateEvidenceDialog open={paperAdding} handleClose={handleAddPaperClose} height={300}/>}
                {reviewing &&
                    <ReviewEvidenceDialog open={reviewing} data={data} handleClose={handleReviewClose} height={300}/>}
                {
                    open &&
                    <ActionMenu open={open} anchorEl={anchorEl} handleClose={handleMoreClose} handleSubmit={handleSubmit}/>
                }
            </CardRoot>
        );
    }
;

export default EvidenceGridCard;