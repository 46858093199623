import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Grid from '@mui/material/Grid';

import useVerticals from 'hooks/vertical/useVerticals';
import LoaderSpinner from 'components/elements/LoaderSpinner';
import InfiniteLoaderList from 'components/elements/InfiniteLoadList';

import VerticalCard from './vertical-card';

const VerticalsList = ({
                          height, refresh = 0, filters = {
        search: '',
    }, sort = {
        name: '',
        order: '',
    }
                      }) => {
    const {t} = useTranslation();
    const [flattenedResults, setFlattenedResults] = useState([]);

    const {data, isFetchingNextPage, hasNextPage, fetchNextPage, isError, status,refetch, isFetched} = useVerticals({
        filters,
        sort,
    });

    useEffect(() => {
        if (data) {
            setFlattenedResults(data.pages.reduce((accum, curr) => {
                return [...accum, ...(curr.data ?? [])];
            }, []))
        }
    }, [data])

    useEffect(() => {
        refetch()
    }, [refresh])

    if (status === 'loading') {
        return <Grid container justifyContent="center" alignItems="center" sx={{width: '100%', height: height - 120}}>
            <Grid item>
                <LoaderSpinner type="Bars" color="#175da8" secondaryColor={"#6abed5"} height={70} width={70}/>
            </Grid>
        </Grid>
    }

    return <>
        {flattenedResults && <InfiniteLoaderList
            height={height}
            data={flattenedResults}
            isFetchingNextPage={isFetchingNextPage}
            hasNextPage={hasNextPage}
            fetchNextPage={fetchNextPage}
            isError={isError}
            itemTemplate={({data,index})=><VerticalCard data={data} index={index} handleRefetch={refetch}/>}
        />}
    </>
};

export default VerticalsList;
