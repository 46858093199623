import React from 'react';
import Menu from '@mui/material/Menu';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Logout from '@mui/icons-material/Logout';
import styled from '@mui/system/styled';
import MuiListItemIcon from '@mui/material/ListItemIcon';
import {MenuList, Popover} from "@mui/material";
import Typography from "@mui/material/Typography";

const ListItemIcon = styled(MuiListItemIcon)({
    minWidth: '36px',
});

const ActionMenu = ({anchorEl, open, handleClose, handleSubmit, handleView, validity = 'RELEVANT'}) => {

    return (
        <React.Fragment>
            <Popover id={'tag-dropdown'} open={open} anchorEl={anchorEl}
                     anchorOrigin={{
                         vertical: 'bottom',
                         horizontal: 'center',
                     }}
                     transformOrigin={{
                         vertical: 'top',
                         horizontal: 'center',
                     }}
                     onClose={handleClose}
            >
                <MenuList dense>
                    <MenuItem sx={{fontSize: '0.875rem', pointerEvents: 'none', cursor: 'default'}}>
                        Manual validation
                    </MenuItem>

                    <MenuItem onClick={() => {
                        handleSubmit('NOT_RELEVANT');
                        handleClose()
                    }}
                              disabled={validity === 'NOT_RELEVANT'}>
                        <Typography sx={{paddingLeft: '1em', fontSize: '0.875rem'}}>Not Relevant</Typography>
                    </MenuItem>
                </MenuList>
            </Popover>
        </React.Fragment>
    );
}

export default ActionMenu;