import React, {lazy, useState, Suspense} from 'react';
import Box from '@mui/material/Box';
import styled from '@mui/system/styled';
import MuiAccordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Grid from '@mui/material/Grid';
import {useTranslation} from 'react-i18next';
import {createSearchParams, useNavigate, useSearchParams} from 'react-router-dom';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Divider from '@mui/material/Divider';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import CachedIcon from '@mui/icons-material/Cached';
import DownloadingIcon from '@mui/icons-material/Downloading';
import {format, parseISO} from 'date-fns';
import Link from '@mui/material/Link';
import MuiHelpOutlineIcon from '@mui/icons-material/HelpOutline';

import useHover from 'hooks/useHover';
import CardHeaderColumn from 'components/elements/CardHeader';
import LabeledChip from 'components/elements/LabeledChip';
import CustomButton from 'components/elements/CustomButton';
import CustomTooltip from 'components/elements/CustomTooltip';
import {ROUTE_INBOX_EXPLORE} from 'pages/constants';

const SearchSessionCardDetails = lazy(() => import('./search-session-card-details'));

const CardRoot = styled(Box)(() => ({
    padding: '2px 0px 6px 0px',
}));

const Accordion = styled(MuiAccordion)(({theme}) => ({
    borderWidth: '0.1em',
    borderStyle: 'solid',
    borderColor: '#E9E9E9',
    boxShadow: 'none',
    backgroundColor: 'transparent'
}));

const HelpOutlineIcon = styled(MuiHelpOutlineIcon)(({theme}) => ({
    fontSize: 'small',
    color: '#696969'
}));

const SearchSessionCard = ({style, data, index, scroll}) => {
        const {t} = useTranslation();
        const [hoverRef] = useHover();
        const navigate = useNavigate();
        let [searchParams] = useSearchParams();

        const [expand, setExpand] = useState(false);

        const [paperAdding, setPaperAdding] = useState(false);
        const [reviewing, setReviewing] = useState(false);

        const handleAdd = (paper) => {
            setPaperAdding(paper)
        }

        const handleAddPaperClose = () => {
            setPaperAdding(null)
        }

        const handleReview = () => {
            setReviewing(true)
        }

        const handleReviewClose = () => {
            setReviewing(false)
        }

        const onToggleExpandRow = () => {
            setExpand((prev) => !prev);
        };

        const isActive = () => {
            return data['run_id'] === searchParams.get('pipeline_id')
        }

        const statusIcon = (status) => {
            switch (status) {
                case 'SUCCESS':
                    return <CheckCircleIcon sx={{color: '#00c360'}}/>;
                case 'ERROR':
                    return <CancelIcon sx={{color: '#ff0000'}}/>;
                case 'QUEUED':
                    return <PendingOutlinedIcon sx={{color: '#EED437'}}/>;
                case 'STARTED':
                    return <CachedIcon sx={{color: '#0894d3'}}/>;
                case 'DOWNLOADING':
                    return <DownloadingIcon sx={{color: '#0894d3'}}/>;
                default:
                    return <></>;
            }
        };

        const getStatus = ({sources = []}) => {
            let status = 'QUEUED'
            let successCount = 0;
            let errorCount = 0;
            let pendingCount = 0;
            let startedCount = 0;
            let downloadingCount = 0;

            sources.forEach(source => {
                switch (source.status) {
                    case 'SUCCESS':
                        successCount++;
                        break;
                    case 'ERROR':
                        errorCount++;
                        break;
                    case 'QUEUED':
                        pendingCount++;
                        break;
                    case 'STARTED':
                        startedCount++;
                        break;
                    case 'DOWNLOADING':
                        downloadingCount++;
                        break;
                    default:
                        break;
                }
            });

            if (errorCount > 0) {
                status = 'ERROR'
            } else if (pendingCount > 0) {
                status = 'QUEUED'
            } else if (downloadingCount > 0) {
                status = 'DOWNLOADING'
            } else if (startedCount > 0) {
                status = 'STARTED'
            } else if (successCount === sources.length) {
                status = 'SUCCESS'
            }
            return status
        };

        const statusTooltip = (status) => {
            switch (status) {
                case 'SUCCESS':
                    return t('Success');
                case 'ERROR':
                    return t('Error');
                case 'QUEUED':
                    return t('Queued');
                case 'STARTED':
                    return t('Started');
                case 'DOWNLOADING':
                    return t('Downloading');
                default:
                    return '';
            }
        };

        const getSource = (source) => {
            switch (source) {
                case 'SEMANTIC_SCHOLAR':
                    return 'Semantic scholar';
                case 'GOOGLE_SCHOLAR':
                    return 'Google scholar';
                case 'MANUAL':
                    return 'Manual';
                default:
                    return '';
            }
        }

        const getColor = (source) => {
            switch (source) {
                case 'SEMANTIC_SCHOLAR':
                    return colors[0];
                case 'GOOGLE_SCHOLAR':
                    return colors[1];
                case 'MANUAL':
                    return colors[2];
                default:
                    return '';
            }
        }
        const handleViewPapers = (value) => {
            navigate({
                pathname: ROUTE_INBOX_EXPLORE,
                search: `?${createSearchParams({'download_session': value})}`
            });
        }

        const colors = ['#daf7ca', '#efefcb', '#c8edfd']

        return (
            <CardRoot style={style} ref={hoverRef}>
                <Accordion expanded={expand} TransitionProps={{unmountOnExit: true}} active={isActive()}>
                    <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{
                            '&.MuiAccordionSummary-root': {
                                boxShadow: '1px 2px 13px -10px #888',
                                backgroundColor: '#fff'
                            },
                            '&.MuiAccordionSummary-root.Mui-expanded': {minHeight: 'unset'},
                            div: {
                                '&.MuiAccordionSummary-content.Mui-expanded': {margin: '12px 0'},
                            },
                            '&:hover': {
                                cursor: 'pointer'
                            }
                        }}

                    >
                        <Grid container alignItems="flex-start" justifyContent="space-between" flexWrap='nowrap' spacing={2}
                            // onClick={onToggleExpandRow}
                        >
                            <Grid container item xs={0.4} alignItems='center' justifyContent='center' sx={{height: '100%'}}>
                                <Grid item>
                                    <CustomTooltip title={statusTooltip(getStatus({sources: data['source_providers']}))}>
                                        {statusIcon(getStatus({sources: data['source_providers']}))}
                                    </CustomTooltip>
                                </Grid>
                            </Grid>
                            <Grid item xs={0.2} sx={{height: '100%'}}>
                                <Divider sx={{
                                    '&.MuiDivider-root': {
                                        borderWidth: '1px',
                                        borderColor: '#a7a7a7',
                                        width: 'fit-content'
                                    }
                                }}
                                         orientation='vertical'/>
                            </Grid>
                            <CardHeaderColumn
                                spacing={5}
                                headerName={t('Session name')}
                                headerTitle={<Grid
                                    item
                                    container
                                    alignItems="center"
                                >
                                    <Grid item>
                                        {data['download_session_name']}
                                        <span style={{paddingLeft: '0.5em', verticalAlign: '-0.1em'}}>
                                            <CustomTooltip title={<span>
                                                <b>Search phrase:</b> {data['search_phrase']['search_phrase']}<br/>
                                                {data['session_metrics'] && <span>
                                                <b>No of papers fetched:</b> {data['session_metrics']['no_of_search_results']}<br/>
                                                <b>No of papers requested:</b> {data['session_metrics']['no_of_papers_requested']}<br/>
                                                <b>No of papers saved:</b> {data['session_metrics']['no_of_papers_saved']}<br/>
                                                <b>No of papers downloaded:</b> {data['session_metrics']['no_of_papers_downloaded']}<br/>
                                                </span>
                                                }
                                                </span>}>
                                            <HelpOutlineIcon/>
                                        </CustomTooltip>
                                        </span>
                                    </Grid>
                                </Grid>}
                            />
                            <CardHeaderColumn
                                spacing={1.6}
                                align={'flex-end'}
                                headerName={t('Downloaded papers')}
                                headerTitle={data['total_paper_count']}
                            />
                            <CardHeaderColumn
                                spacing={1.6}
                                headerName={t('Source providers')}
                                headerContent={<Grid container spacing={0.5}>
                                    {data['source_providers']?.map(provider => {
                                        return <Grid item><LabeledChip label={t(getSource(provider.source_provider))}
                                                                       backgroundColor={getColor(provider.source_provider)}/></Grid>
                                    })}</Grid>}
                            />
                            <CardHeaderColumn
                                spacing={1.6}
                                headerName={t('Created at')}
                                headerTitle={format(parseISO(data.created_at), 'Pp')}
                            />
                            {/*<CardHeaderColumn*/}
                            {/*    spacing={1.6}*/}
                            {/*    headerName={t('Created by')}*/}
                            {/*    headerTitle={'-'}*/}
                            {/*/>*/}
                            <Grid item container spacing={0.5} justifyContent={"flex-end"} alignItems={'center'} xs={1.6}
                                  sx={{height: '100%'}}>
                                {/*<Grid item><IconButton onClick={()=>handleAdd(data)}><PlayArrowIcon*/}
                                {/*    fontSize={'small'}/></IconButton></Grid>*/}
                                {!['ERROR', 'SUCCESS'].includes(getStatus({sources: data['source_providers']})) &&
                                    <Grid item>
                                        <CustomButton>
                                            Cancel
                                        </CustomButton>
                                    </Grid>
                                }
                                <Link onClick={() => handleViewPapers(data.download_session_id)}
                                      sx={{
                                          fontSize: '0.875rem',
                                          cursor: 'pointer'
                                      }}>{t('inbox.searchPhrases.viewPapers')}</Link>
                                {/*<Grid item>*/}
                                {/*<IconButton*/}
                                {/*    onClick={(e) => {*/}
                                {/*        e.stopPropagation();*/}
                                {/*        onToggleExpandRow();*/}
                                {/*    }}*/}
                                {/*>*/}
                                {/*    <ExpandMoreIcon sx={{transform: `rotate(${expand ? 180 : 0}deg)`}}/>*/}
                                {/*</IconButton>*/}
                                {/*</Grid>*/}
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Suspense fallback={<Grid>Loading...</Grid>}>
                            <SearchSessionCardDetails data={data}/>
                        </Suspense>
                    </AccordionDetails>
                </Accordion>
            </CardRoot>
        );
    }
;

export default SearchSessionCard;