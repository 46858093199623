import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import Grid from '@mui/material/Grid';

import VerticalsList from './verticals-list';
import Filters from './filters';
import CustomButton from "../../../../components/elements/CustomButton";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import UploadIcon from "@mui/icons-material/Upload";
import RefreshIcon from "@mui/icons-material/Refresh";
import PostAddIcon from "@mui/icons-material/PostAdd";

const ManageVerticals = ({containerHeight}) => {
    const {t} = useTranslation();
    const [filters, setFilters] = React.useState({
        search: '',});

    const [sort, setSort] = useState({});

    const [refresh, setRefresh] = useState(0);
    const handleSearch = (search) => {
        setFilters({
            ...filters,
            search
        })
    }

    const handleRefresh = () => {
        setRefresh(Math.random())
    }

    return (
        <Grid container flexDirection="column" flexWrap="nowrap" spacing={1} sx={{padding: '0 !important'}}>

            <Grid item container xs={12} alignItems={'center'}>
                <Filters handleSearch={handleSearch} filters={filters} handleFilters={setFilters}/>
            </Grid>

            <Grid item container xs={12} justifyContent={'space-between'}>

                <Grid item container xs={12} justifyContent={'flex-end'} spacing={3}>
                    <Grid item>
                        <CustomButton sx={{color: '#656565'}} startIcon={<RefreshIcon sx={{color: '#0794d3'}}/>}
                                      disableRipple
                                      onClick={handleRefresh}>{t('Refresh')}</CustomButton>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <VerticalsList height={containerHeight - 232}
                               refresh={refresh}
                               sort={sort} filters={filters}/>

            </Grid>
        </Grid>
    );
}

export default ManageVerticals;
