import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Grid from '@mui/material/Grid';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import RefreshIcon from '@mui/icons-material/Refresh';
import GridViewIcon from '@mui/icons-material/GridView';
import IconButton from '@mui/material/IconButton';
import ViewDayIcon from '@mui/icons-material/ViewDay';
import Typography from '@mui/material/Typography';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PlagiarismOutlinedIcon from '@mui/icons-material/PlagiarismOutlined';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {useNavigate} from 'react-router-dom';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import {Checkbox, FormControlLabel} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {Hidden} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';

import PageContainer from 'components/elements/PageContainer';
import CustomButton from 'components/elements/CustomButton';
import CustomTooltip from 'components/elements/CustomTooltip';
import ResizableContainer from 'components/elements/ResizableContainer';
import {ROUTE_PIPELINES_EXTRACTIONS} from 'pages/constants';
import {notifyChangeEvidencesFilters} from 'state/app';

import EvidenceList from './evidence-list';
import Filters from './filters';
import ExtractEvidenceDialog from './extractEvidences/extract-evidence-dialog';
import EvidenceGrid from './evidence-grid';

const StatusFilter = ({handleChange, name, status, icon, currentStatus}) => {
    return <Grid item container width={'auto'} sx={{cursor: 'pointer'}}
                 onClick={() => handleChange(status)}>
        {icon}
        <Typography fontSize={'0.875rem'}
                    sx={{paddingLeft: '4px', ...(currentStatus === status && {color: "#1979da", fontWeight: 600})}}>
            {name}
        </Typography>
    </Grid>
}
const EvidenceExplore = () => {
    const {t} = useTranslation();

    return (
        <PageContainer pageTitle={t('evidence.explore')}>
            <EvidenceExplorePage/>
        </PageContainer>
    );
};

const EvidenceExplorePage = ({containerHeight}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const selectedVertical = useSelector((state) => state.app.vertical);
    const filters = useSelector((state) => state.app.evidencesPage.filters);
    const paperId = useSelector((state) => state.app.evidencesPage.paperId);

    const [view, setView] = useState('grid');

    const [sort, setSort] = useState({
        relevant_paper_model_prediction_score: 'desc',
        citation_count: 'desc',
        published_year: 'desc',
        created_at: 'desc',
        current: 'created_at'
    });

    const [extracting, setExtracting] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [count, setCount] = useState({
        total_count: 0,
        not_extracted_count: 0,
        success_count: 0,
        partial_count: 0,
        failed_count: 0

    });
    const [selectedEvidences, setSelectedEvidences] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    useEffect(() => {
        setSelectedEvidences([])
    }, [selectedVertical])

    const handleAdd = () => {
        setExtracting(true)
    }

    const handleExtractClose = ({navigate = false}) => {
        setExtracting(false);
        if (navigate) {
            handleGoToExtractSessions();
        }
    }

    const handleRefresh = () => {
        setRefresh(Math.random())
    }

    const handleChangeView = (value) => {
        setView(value)
    }

    const handleFilters = (value) => {
        dispatch(notifyChangeEvidencesFilters(value));
    }

    const handleChangeRunStatus = (paper_evidence_status) => {
        handleFilters({...filters, paper_evidence_status})
    }

    const handleGoToExtractSessions = () => {
        navigate({
            pathname: ROUTE_PIPELINES_EXTRACTIONS
        });
    };

    const handleSelect = (evidenceId) => {
        let selected = [...selectedEvidences]
        if (selectedEvidences.includes(evidenceId)) {
            selected = selected.filter(id => id !== evidenceId)
        } else {
            selected.push(evidenceId)
        }
        setSelectedEvidences(selected)
    }

    const handleCount = (value) => {
        setCount(value)
    }

    const handleSelectAll = () => {
        setSelectAll(prev => !prev)
    }

    return (
        // <ResizableContainer paperId={paperId}>
        <Grid container flexDirection="column" flexWrap="nowrap" spacing={1} sx={{padding: '0 1em !important'}}>

            <Grid item container xs={12} alignItems={'center'}>
                <Filters/>
            </Grid>

            <Grid item container xs={12} justifyContent={'space-between'} spacing={3}>
                <Grid item container xs={4} lg={8} spacing={2} flexWrap={'nowrap'} alignItems={'center'}>
                    <Grid item>
                        <FormControlLabel
                            sx={{marginLeft: 0}}
                            control={
                                <Checkbox checked={selectAll}
                                          size="small"
                                          onChange={() => handleSelectAll()}
                                          name="gilad"/>
                            }
                            labelPlacement="end"
                            label="Select All"
                        />
                    </Grid>
                    <Hidden xlDown>
                        <StatusFilter name={`Marked for Extraction: ${count.not_extracted_count}`}
                                      status={"NOT_EXTRACTED"}
                                      currentStatus={filters.paper_evidence_status}
                                      icon={<PlagiarismOutlinedIcon sx={{color: '#0894d3', fontSize: '1.25rem'}}/>}
                                      handleChange={handleChangeRunStatus}/>

                        <StatusFilter name={`Success: ${count.success_count}`}
                                      status={"SUCCESS"}
                                      currentStatus={filters.paper_evidence_status}
                                      icon={<CheckCircleIcon sx={{color: '#00c360', fontSize: '1.25rem'}}/>}
                                      handleChange={handleChangeRunStatus}/>

                        <StatusFilter name={`Failed: ${count.failed_count}`}
                                      status={"FAILED"}
                                      currentStatus={filters.paper_evidence_status}
                                      icon={<WarningAmberIcon sx={{color: '#df812e', fontSize: '1.25rem'}}/>}
                                      handleChange={handleChangeRunStatus}/>

                        <StatusFilter name={`Partially Extracted: ${count.partial_count}`}
                                      status={"PARTIAL"}
                                      currentStatus={filters.paper_evidence_status}
                                      icon={<AutoModeIcon sx={{color: '#EED437', fontSize: '1.25rem'}}/>}
                                      handleChange={handleChangeRunStatus}/>
                        {filters.paper_evidence_status !== '' && <StatusFilter name={"Clear"}
                                                                               status={""}
                                                                               icon={<CloseIcon
                                                                                   sx={{fontSize: '1.25rem'}}/>}
                                                                               handleChange={handleChangeRunStatus}/>
                        }
                    </Hidden>
                </Grid>

                <Grid item container xs={8} lg={4} spacing={2} flexWrap={'nowrap'} justifyContent={'flex-end'}>
                    <Grid item>
                        <CustomTooltip
                            title={(selectAll ? !selectAll : selectedEvidences.length === 0) ? "No papers selected for evidence extraction" : "Extract evidences"}>
                           <span> <CustomButton sx={{color: '#656565'}} onClick={handleAdd} startIcon={<PlayArrowIcon
                               sx={{color: '#49ac43'}}/>}
                                                disabled={selectAll ? !selectAll : selectedEvidences.length === 0}>{t('Extract Evidences')}</CustomButton>
                               </span>
                        </CustomTooltip>
                    </Grid>
                    <Grid item>
                        <CustomButton sx={{color: '#656565'}} startIcon={<RefreshIcon sx={{color: '#0794d3'}}/>}
                                      onClick={handleRefresh}>{t('Refresh')}</CustomButton>
                    </Grid>
                    <Grid item sx={{width: 'fit-content'}} container spacing={0.5}>
                        <Grid item>
                            <IconButton onClick={() => handleChangeView('grid')}>
                                <GridViewIcon size={'small'} sx={{...(view === 'grid' && {color: '#000'})}}/>
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={() => handleChangeView('list')}>
                                <ViewDayIcon size={'small'} sx={{...(view === 'list' && {color: '#000'})}}/>
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <ResizableContainer paperId={paperId} height={containerHeight - 168}>

                {view === 'list' && <Grid item xs={12}>
                    <EvidenceList height={containerHeight - 168}
                                  refresh={refresh}
                                  handleSelect={handleSelect}
                                  selectedEvidences={selectedEvidences}
                                  selectAll={selectAll}
                                  handleCount={handleCount}
                                  sort={sort}/>

                </Grid>
                }
                {view === 'grid' && <Grid item xs={12}>
                    <EvidenceGrid
                        height={containerHeight - 168}
                        refresh={refresh}
                        sort={sort}
                        handleSelect={handleSelect}
                        handleCount={handleCount}
                        selectAll={selectAll}
                        selectedEvidences={selectedEvidences}
                    />

                </Grid>
                }
            </ResizableContainer>
            <Grid item xs={12} container justifyContent={'flex-end'}>
                <Grid item>
                    <Typography sx={{fontWeight: 600, fontSize: '0.875rem'}}>Paper
                        Count: {count.total_count}</Typography>
                </Grid>
            </Grid>
            {extracting &&
                <ExtractEvidenceDialog open={extracting} handleClose={handleExtractClose} height={containerHeight}
                                       selectAll={selectAll}
                                       total={count.total_count}
                                       filters={filters}
                                       selected={selectedEvidences}/>}
        </Grid>
        // </ResizableContainer>
    );
}

export default EvidenceExplore;
