import {Panel, PanelGroup, PanelResizeHandle} from 'react-resizable-panels';
import React, {useRef} from 'react';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import Grid from '@mui/material/Grid';
import PdfViewer from '../PdfViewer';

const ResizableContainer = ({children,paperId,height}) => {
    const viewer = useRef(null);

    return <PanelGroup direction="horizontal" id="group" autoSaveId="persistence">
        <Panel id="left-panel">
            {children}
        </Panel>
        {paperId && <PanelResizeHandle id="resize-handle">
            <Grid container sx={{height:'100%'}} direction={'column'} justifyContent={'center'}>
                <Grid item>
                <DragHandleIcon sx={{transform: 'rotate(90deg)'}}/>
                </Grid>
            </Grid>
        </PanelResizeHandle>}
        {paperId && <Panel id="right-panel">
            <PdfViewer paperId={paperId} height={height} viewer={viewer} />
        </Panel>}
    </PanelGroup>
}

export default ResizableContainer