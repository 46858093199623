import React from 'react';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import Grid from '@mui/material/Grid';
import {
    FormControlLabel, Radio,
    RadioGroup,
    TextField
} from '@mui/material';
import {Formik} from 'formik';
import Box from '@mui/material/Box';

import LabeledCustomSelect from 'components/elements/LabeledCustomSelect';

const SelectVerticalForm = ({
                                formRef,
                                onSubmit,
                                verticals = [],
                                selectedVertical = '',
    request
                            }) => {

    const {t} = useTranslation();

    const validationSchema = Yup.object().shape({
        create_new: Yup.boolean(),
        vertical_name: Yup.string()
            .when('create_new', {
                is: true,
                then: () => Yup.string().required(t('Vertical name is required')),
                otherwise: () => Yup.string(),
            }),
        vertical: Yup.string().required(t('Vertical is required')),
    });

    return (
        <Grid conainer item xs={12}>
            <Formik
                initialValues={{
                    vertical_name: request?request['vertical_name']:'',
                    vertical: request?request['vertical']:(selectedVertical ?? ''),
                    create_new: request ? request['create_new'] : false,
                }}
                innerRef={formRef}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({
                      handleChange,
                      values,
                      touched,
                      errors,
                      setFieldValue
                  }) => (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <RadioGroup value={values.create_new}
                                        name={'create_new'}
                                        onChange={(event,value)=>{
                                            setFieldValue('vertical','');
                                            setFieldValue('create_new',event.target.value==='true');
                                        }}>
                                <Grid item xs={12} container>
                                    <Grid item xs={6}>
                                        <Box m={1}>
                                            <FormControlLabel
                                                value={false}
                                                control={<Radio />}
                                                label='Continue with existing vertical'
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box m={1}>
                                            <FormControlLabel
                                                value={true}
                                                control={<Radio />}
                                                label='Create new vertical'
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </RadioGroup>
                        </Grid>
                        {!values.create_new && <Grid item xs={12}>
                                <LabeledCustomSelect
                                    placeholder={t('Vertical')}
                                    label={t('Vertical')} p
                                    id="select-vertical"
                                    name="vertical"
                                    InputLabelProps={{shrink: false}}
                                    selectItems={verticals}
                                    height={"calc(100vh/2)"}
                                    value={values.vertical}
                                    error={Boolean(touched.vertical && errors.vertical)}
                                    helperText={touched.vertical && errors.vertical}
                                    onChange={(e) => setFieldValue('vertical', e.target.value)}
                                />
                            </Grid>}
                        {values.create_new && <>
                            <Grid item xs={12}>
                                <TextField id="name"
                                           label={t("Vertical")}
                                           required
                                           name="vertical"
                                           value={values.vertical}
                                           onChange={handleChange}
                                           error={Boolean(touched.vertical && errors.vertical)}
                                           helperText={touched.vertical && errors.vertical}
                                           fullWidth
                                           variant="outlined" size="small"/>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField id="name"
                                           label={t("Vertical Name")}
                                           required
                                           name="vertical_name"
                                           value={values.vertical_name}
                                           onChange={handleChange}
                                           error={Boolean(touched.vertical_name && errors.vertical_name)}
                                           helperText={touched.vertical_name && errors.vertical_name}
                                           fullWidth
                                           variant="outlined" size="small"/>
                            </Grid>
                        </>
                        }
                    </Grid>
                )}
            </Formik>
        </Grid>
    );
};

export default SelectVerticalForm;