import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Grid from '@mui/material/Grid';
import {useNavigate} from 'react-router-dom';
import Highcharts from 'highcharts';

import PageContainer from 'components/elements/PageContainer';
import GraphCard from 'components/elements/GraphCard';
import SummaryCard from 'components/elements/SummaryCard';
import useDashboard from 'hooks/dashboard/useDashboard';
import {ROUTE_EVIDENCE_EXPLORE, ROUTE_INBOX_EXPLORE, ROUTE_PLAYS_EXPLORE} from 'pages/constants';

import PieChart from './pie-chart';
import ColumnChart from './column-chart';
import BarChart from './bar-chart';
import StackedBarChart from './stacked-bar-chart';
import CreationOverTime from './creation-over-time';
import CurationProgressChart from "./curation-progress-chart";
import Filters from "./filters";

const Dashboard = () => {
    const {t} = useTranslation();

    return (
        <PageContainer pageTitle={t('app.dashboard.pageTitle')} pageSubTitle={t('app.dashboard.pageSubTitle')}>
            <DashboardPage/>
        </PageContainer>
    );
};

const DashboardPage = ({containerHeight}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const [results, setResults] = useState(null);
    const [filters, setFilters] = useState({sortBy: 'RECENT'});
    const {data, status} = useDashboard({});

    useEffect(() => {
        if (data && data.data) {
            setResults(data.data)
        }
    }, [data])

    const handleViewPapers = () => {
        navigate(ROUTE_INBOX_EXPLORE);
    };

    const handleViewEvidences = () => {
        navigate(ROUTE_EVIDENCE_EXPLORE);
    };

    const handleViewPlays = () => {
        navigate(ROUTE_PLAYS_EXPLORE);
    };

    return (
        <Grid container spacing={2} sx={{padding: '1em !important', overflow: 'auto', height: containerHeight - 8}}>
            <>{results && <Grid xs={12} item container spacing={2} flexWrap={'nowrap'}>
                <SummaryCard title={'Total verticals'} count={results['vertical_count']}/>
                <SummaryCard title={'Total search sessions'} count={results['search_session_count']}/>
                <SummaryCard title={'Total papers'} count={results['paper_count']} onClick={handleViewPapers}/>
                <SummaryCard title={'Total evidences'} count={results['evidence_count']} onClick={handleViewEvidences}/>
                <SummaryCard title={'Total plays'} count={"-"} onClick={handleViewPlays}/>
            </Grid>}</>

            <>{results && <Grid xs={12} item container spacing={2}>
                <Grid xs={4} item>
                    <GraphCard title={'Papers By Vertical'}>
                        <PieChart data={results['paper_distribution']}/>
                    </GraphCard>
                </Grid>
                <Grid xs={8} item>
                    <CreationOverTime/>
                </Grid>
            </Grid>}</>

            <Grid xs={12} item container spacing={2} justifyContent={'flex-end'}>
                <Grid xs={4} md={3} item>
                    <Filters filters={filters}/>
                </Grid>
            </Grid>

            <>{results && <Grid xs={12} item container spacing={2}>

                <Grid xs={6} item>
                    <GraphCard title={'Evidence Extracted By Vertical'}>
                        <ColumnChart data={results['paper_distribution']}/>
                    </GraphCard>
                </Grid>
                <Grid xs={6} item>
                    <GraphCard title={'Curation progress by vertical'}>
                        <CurationProgressChart maxHeight={'450px'} data={results['curation_progress']}/>
                    </GraphCard>
                </Grid>


                <Grid xs={6} item>
                    <GraphCard title={'Classification model test score distribution'}>
                        <BarChart maxHeight={'400px'} data={results['model_score_distribution']}/>
                    </GraphCard>
                </Grid>
                <Grid xs={6} item>
                    <GraphCard title={'Manual validation overlap with classification model'}>
                        <StackedBarChart data={results['validation_overlap']} maxHeight={'400px'}/>
                    </GraphCard>
                </Grid>
            </Grid>}</>
        </Grid>
    );
};

export default Dashboard;
