import React, {lazy, useState, Suspense} from 'react';
import Box from '@mui/material/Box';
import styled from '@mui/system/styled';
import MuiAccordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Grid from '@mui/material/Grid';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Divider from '@mui/material/Divider';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import CachedIcon from '@mui/icons-material/Cached';
import {format, parseISO} from 'date-fns';

import useHover from 'hooks/useHover';
import CardHeaderColumn from 'components/elements/CardHeader';
import LabeledChip from 'components/elements/LabeledChip';
import CustomButton from 'components/elements/CustomButton';
import CustomTooltip from "components/elements/CustomTooltip";

const SearchSessionCardDetails = lazy(() => import('./extraction-session-card-details'));

const CardRoot = styled(Box)(() => ({
    padding: '2px 0px 6px 0px',
}));

const Accordion = styled(MuiAccordion)(({theme}) => ({
    borderWidth: '0.1em',
    borderStyle: 'solid',
    borderColor: '#E9E9E9',
    boxShadow: 'none',
    backgroundColor: 'transparent'
}));

const ExtractionSessionCard = ({style, data, index, scroll}) => {
        const {t} = useTranslation();
        const [hoverRef] = useHover();
        let [searchParams] = useSearchParams();

        const [expand, setExpand] = useState(false);

        const isActive = () => {
            return data['run_id'] === searchParams.get('pipeline_id')
        }

        const statusIcon = (status) => {
            switch (status) {
                case 'SUCCESS':
                    return <CheckCircleIcon sx={{color: '#00c360'}}/>;
                case 'ERROR':
                    return <CancelIcon sx={{color: '#ff0000'}}/>;
                case 'QUEUED':
                    return <PendingOutlinedIcon sx={{color: '#EED437'}}/>;
                case 'RUNNING':
                    return <CachedIcon sx={{color: '#0894d3'}}/>;
                default:
                    return <></>;
            }
        };

        const getStatus = ({sources = []}) => {
            let status = 'QUEUED'
            let successCount = 0;
            let errorCount = 0;
            let pendingCount = 0;
            let startedCount = 0;

            sources.forEach(source => {
                switch (source.status) {
                    case 'SUCCESS':
                        successCount++;
                        break;
                    case 'ERROR':
                        errorCount++;
                        break;
                    case 'QUEUED':
                        pendingCount++;
                        break;
                    case 'RUNNING':
                        startedCount++;
                        break;
                    default:
                        break;
                }
            });

            if (errorCount > 0) {
                status = 'ERROR'
            } else if (pendingCount > 0) {
                status = 'QUEUED'
            } else if (startedCount > 0) {
                status = 'RUNNING'
            } else if (successCount === sources.length) {
                status = 'SUCCESS'
            }
            return status
        };

        const getSource = (source) => {
            switch (source) {
                case 'GPT':
                    return 'GPT';
                case 'CHAT_GPT':
                    return 'GPT';
                case 'CHAT_GPT_KOR':
                    return 'GPT KOR';
                case 'MANUAL':
                    return 'Manual';
                default:
                    return '';
            }
        }

        const getColor = (source) => {
            switch (source) {
                case 'GPT':
                    return colors[0];
                case 'CHAT_GPT':
                    return colors[0];
                case 'MANUAL':
                    return colors[2];
                default:
                    return '';
            }
        }

        const statusTooltip = (status) => {
            switch (status) {
                case 'SUCCESS':
                    return t('Success');
                case 'ERROR':
                    return t('Error');
                case 'QUEUED':
                    return t('Queued');
                case 'RUNNING':
                    return t('Running');
                default:
                    return '';
            }
        };

        const colors = ['#daf7ca', '#efefcb', '#c8edfd']

        return (
            <CardRoot style={style} ref={hoverRef}>
                <Accordion expanded={expand} TransitionProps={{unmountOnExit: true}} active={isActive()}>
                    <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{
                            '&.MuiAccordionSummary-root': {
                                boxShadow: '1px 2px 13px -10px #888',
                                backgroundColor: '#fff',
                            },
                            '&.MuiAccordionSummary-root.Mui-expanded': {minHeight: 'unset'},
                            div: {
                                '&.MuiAccordionSummary-content.Mui-expanded': {margin: '12px 0'},
                            },
                            '&:hover': {
                                cursor: 'pointer'
                            }
                        }}

                    >
                        <Grid container justifyContent="space-between" flexWrap='nowrap' spacing={1}
                            // onClick={onToggleExpandRow}
                        >
                            <Grid container item xs={0.4} alignItems='center' justifyContent='center' sx={{height: '100%'}}>
                                <Grid item>
                                    <CustomTooltip title={statusTooltip(data['status'])}>
                                        {statusIcon(data['status'])}</CustomTooltip></Grid>

                            </Grid>
                            <Grid item xs={0.2} sx={{height: '100%'}}>
                                <Divider sx={{
                                    '&.MuiDivider-root': {
                                        borderWidth: '1px',
                                        borderColor: '#a7a7a7',
                                        width: 'fit-content'
                                    }
                                }}
                                         orientation='vertical'/>
                            </Grid>
                            <CardHeaderColumn
                                spacing={4}
                                headerName={t('Session name')}
                                headerTitle={<Grid
                                    item
                                    container
                                    alignItems="center"
                                >
                                    <Grid item>{data['extraction_session_name']}</Grid>
                                </Grid>}
                            />
                            <CardHeaderColumn
                                spacing={1.6}
                                headerName={t('Extraction count')}
                                headerTitle={data['total_evidences']}
                            />
                            <CardHeaderColumn
                                spacing={1.6}
                                headerName={t('Extraction type')}
                                headerContent={<Grid container spacing={0.5}>
                                    <Grid item><LabeledChip label={t(getSource(data['extraction_type']))}
                                                            backgroundColor={getColor(data['extraction_type'])}/></Grid>
                                </Grid>}
                            />
                            <CardHeaderColumn
                                spacing={2.6}
                                headerName={t('Created at')}
                                headerTitle={data.created_at ? format(parseISO(data.created_at), 'Pp') : ''}
                            />
                            {/*<CardHeaderColumn*/}
                            {/*    spacing={1.6}*/}
                            {/*    headerName={t('Created by')}*/}
                            {/*    headerTitle={'-'}*/}
                            {/*/>*/}
                            <Grid item container spacing={0.5} justifyContent={"flex-end"} alignItems={'center'} xs={1.6}
                                  sx={{height: '100%'}}>
                                {/*<Grid item><IconButton onClick={()=>handleAdd(data)}><PlayArrowIcon*/}
                                {/*    fontSize={'small'}/></IconButton></Grid>*/}
                                {!['ERROR', 'SUCCESS'].includes(getStatus({sources: data['source_providers']})) &&
                                    <Grid item>
                                        <CustomButton>
                                            Cancel
                                        </CustomButton>
                                    </Grid>
                                }
                                {/*<Grid item>*/}
                                {/*<IconButton*/}
                                {/*    onClick={(e) => {*/}
                                {/*        e.stopPropagation();*/}
                                {/*        onToggleExpandRow();*/}
                                {/*    }}*/}
                                {/*>*/}
                                {/*    <ExpandMoreIcon sx={{transform: `rotate(${expand ? 180 : 0}deg)`}}/>*/}
                                {/*</IconButton>*/}
                                {/*</Grid>*/}
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Suspense fallback={<Grid>Loading...</Grid>}>
                            <SearchSessionCardDetails data={data}/>
                        </Suspense>
                    </AccordionDetails>
                </Accordion>
            </CardRoot>
        );
    }
;

export default ExtractionSessionCard;