import React, {useEffect, useState} from 'react';
import {NavLink, useLocation} from 'react-router-dom';
import styled from '@mui/system/styled';
import MuiList from '@mui/material/List';
import MuiListItem from '@mui/material/ListItem';
import MuiListItemIcon from '@mui/material/ListItemIcon';
import MuiListItemButton from '@mui/material/ListItemButton';
import MuiListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';

import {appRoutesGrouped, pathToIndex} from 'pages/constants';
import CompanyLogo from 'components/images/CompanyLogo';

const Branding = styled('div', {
    shouldForwardProp: (prop) => prop !== 'drawerOpen',
})(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    ...theme.mixins.toolbar,
    cursor: 'pointer',
    justifyContent: 'center',
    marginBottom: '1em',
}));

const ListItemIcon = styled(MuiListItemIcon, {
    shouldForwardProp: (prop) => prop !== 'active',
})(({ theme, active }) => ({
    '&.MuiListItemIcon-root': {
        fontSize: '3rem',
        justifyContent: 'center',
        color: active ? theme.cml.sideBar.activeItemColor : theme.cml.sideBar.inactiveItemColor,
    },
    '& .MuiSvgIcon-root': {
        fontSize: '2rem',
    },
}));

const ListItemText = styled(MuiListItemText, {
    shouldForwardProp: (prop) => prop !== 'active',
})(({ theme, active }) => ({
    '& .MuiTypography-root': {
        fontFamily: 'Open Sans',
        fontSize: '0.8rem',
        whiteSpace: 'nowrap',
        color: active ? theme.cml.sideBar.activeItemColor : theme.cml.sideBar.inactiveItemColor,
    },
}));

const SideBar = () => {
    const location = useLocation();
    const [activeTab, setActiveTab] = useState(0);
    // const { role } = useSelector((state) => state.auth.role);

    useEffect(() => {
        setActiveTab(pathToIndex[location.pathname.split('/').slice(0, 3).join('/')]);
    }, [location]);

    const visible = (item) => {
        // let isVisible = false;

        // if (item.allowed_roles) {
        //     if (role.includes('demo') && (item.path === '/evaluate/summary' || item.path === '/take-action/plays')) {
        //         return false;
        //     } else {
        //         return isVisible && item.allowed_roles.find((role) => roles.includes(role));
        //     }
        // } else {
        //     return isVisible;
        // }

        return true;
    };

    const calculateNavigationBarItems = () => {
        return appRoutesGrouped.reduce((acc, {group, items}) => {
            if (group.name) {
                acc.push(
                    <MuiListItem
                        sx={{pt: '0', pb: '0', '&.MuiListItem-root': {textAlign: 'center'}}}
                        key={group.name}
                    >
                        <ListItemText
                            primary={group.name}
                            sx={{'& .MuiTypography-root': {color: 'black', fontWeight: 'bold', fontSize: '0.8rem'}}}
                        />
                    </MuiListItem>
                );
            }

            items.forEach((item) => {
                acc.push(
                    <MuiListItem sx={{pt: '0', pb: '0'}} key={item.id}>
                        <MuiListItemButton
                            component={NavLink}
                            to={item.path}
                            sx={{
                                py: '1em',
                                px: 0,
                                justifyContent: 'center',
                                flexDirection: 'column',
                                paddingBottom: 0,
                            }}
                            disableRipple
                        >
                            <ListItemIcon sx={{'.MuiSvgIcon-root ': {fontSize: '1.5rem'}}}
                                          active={item.id === activeTab}>{item.icon}</ListItemIcon>
                            <ListItemText active={item.id === activeTab} primary={item.displayName}/>
                        </MuiListItemButton>
                    </MuiListItem>
                );
            });

            if (appRoutesGrouped.length !== group.id + 1) {
                acc.push(
                    <MuiListItem sx={{paddingTop: '0.25em', pb: '0.25em'}} key={group.id}>
                        <ListItemIcon
                            sx={{
                                '&.MuiListItemIcon-root': {
                                    justifyContent: 'center',
                                    backgroundColor: '#d8d8d8',
                                    width: '100%',
                                },
                            }}
                        >
                            <Divider/>
                        </ListItemIcon>
                    </MuiListItem>
                );
            }

            return acc;
        }, []);
    };

    return (
        <>
            <Branding>
                <CompanyLogo width="4rem" />
            </Branding>

            <MuiList sx={{ pt: '0',overflow: 'auto',height: 'calc(100vh - 92px )' }}>
                {calculateNavigationBarItems().map((item) => {
                    return <>{item}</>;
                })}
            </MuiList>
        </>
    );
};

export default SideBar;
