import React, {useEffect, useRef, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import {useTranslation} from 'react-i18next';
import {Button} from '@mui/material';
import Link from '@mui/material/Link';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import ReactJson from '@microlink/react-json-view'

import CustomButton from 'components/elements/CustomButton';
import CustomTooltip from 'components/elements/CustomTooltip';
import useEvidenceCreate from 'hooks/evidences/useEvidenceCreate';
import AlertCard from 'components/elements/AlertCard';
import ViewPdfDrawer from 'components/elements/viewPdfDrawer';
import useEvidenceUpdate from 'hooks/evidences/useEvidenceUpdate';
import ResizableContainer from 'components/elements/ResizableContainer';

import EvidenceForm from '../evidence-form';
import EvidenceDetails from '../evidence-details';
import {ExtractionItem} from '../extraction-item';

const ReviewEvidenceDialog = ({open, handleClose, data, height}) => {
    const {t} = useTranslation();
    const [selectedEvidence, setSelectedEvidence] = useState(null);
    const [curatedEvidence, setCuratedEvidence] = useState(null);
    const [viewOriginal, setViewOriginal] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(null);

    const formRef = useRef();
    const pdfRef = useRef();
    const sourceRef = useRef();

    const {mutateAsync, status} = useEvidenceCreate();
    const {mutateAsync: mutateAsyncUpdate, status: statusUpdate} = useEvidenceUpdate();


    useEffect(() => {
        setSelectedEvidence(data?.extracted_evidences.filter(evidence => evidence.extraction_type !== 'CURATED')[0])
        setCuratedEvidence(data?.extracted_evidences.filter(evidence => evidence.extraction_type === 'CURATED')[0])
    }, [data])

    const handleSave = () => {
        formRef.current.handleSubmit();
    }

    const handleSubmit = (values) => {


        if (curatedEvidence) {
            const req = {
                extracted_evidences: values,
            }
            mutateAsyncUpdate({req, evidenceId: curatedEvidence.evidence_id}).then(() => {
                handleClose()
            });
        } else {
            const req = {
                paper_id: data.paper_id,
                extracted_evidences: values,
                extraction_type: 'CURATED',
            }
            mutateAsync({req}).then(() => {
                handleClose()
            });
        }
    }
    const handleSelect = (evidence) => {
        setSelectedEvidence(evidence)
    }

    const handleViewSource = () => {
        if (sourceRef && sourceRef.current) {
            sourceRef.current.click();
        }
    }

    const handleView = () => {
        setViewOriginal((prevState) => !prevState)
    }

    const handleDrawerOpen = (event) => {
        event.stopPropagation();
        setDrawerOpen(data['paper_id'])
    }

    const handleDrawerClose = () => {
        setDrawerOpen(null)
    }


    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                fullScreen
            >
                <DialogContent sx={{padding: '2rem', minHeight: '50vh'}}>
                    <Stack direction='row' spacing={1} justifyContent='flex-end'>
                        <IconButton
                            aria-label='close'
                            onClick={handleClose}
                            sx={{
                                backgroundColor: '#333333',
                                padding: '4px',
                                '&:hover': {
                                    backgroundColor: '#333333',
                                },
                            }}
                        >
                            <CloseIcon sx={{color: '#ffffff', fontSize: '1.2rem'}}/>
                        </IconButton>
                    </Stack>
                    <Grid container spacing={2}>
                        <Grid item xs={11} container spacing={1}>
                            <Grid item xs={12}
                                  sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                <Typography sx={{
                                    fontWeight: '700',
                                    fontSize: '1.2rem'
                                }}>{t('Review evidences')} for paper: {data['paper_name']}</Typography>
                            </Grid>
                            <Grid item xs={12} sx={{paddingTop: '4px !important', paddingBottom: '1em'}}>
                                <Divider sx={{borderBottom: '2px solid black;', width: '100%'}}/>
                            </Grid>
                        </Grid>


                        <Grid item container xs={11} justifyContent={'flex-end'} spacing={1} columnSpacing={3}
                              sx={{paddingTop: '0 !important'}}>
                            {/*<Grid item>*/}
                            {/*    <Link href={data.source_url} target={"_blank"} sx={{display: 'none'}} ref={pdfRef}/>*/}
                            {/*    <CustomButton sx={{color: '#656565'}} onClick={handleDrawerOpen}*/}
                            {/*                  startIcon={<OpenInNewIcon sx={{color: '#0794d3'}}/>}>*/}
                            {/*        {t('View PDF')}*/}
                            {/*    </CustomButton>*/}
                            {/*</Grid>*/}
                            <Grid item>
                                <Link href={data.provider_url} target={"_blank"} sx={{display: 'none'}}
                                      ref={sourceRef}/>
                                <CustomButton sx={{color: '#656565'}}
                                              startIcon={<OpenInNewIcon sx={{color: '#0794d3'}}/>}
                                              onClick={handleViewSource}>
                                    {t('View Source')}
                                </CustomButton>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" onClick={handleSave}>Save</Button>
                            </Grid>
                        </Grid>
                        <Grid item xs={11}>
                            <ResizableContainer paperId={data['paper_id']} height={'calc(100vh - 196px)'}>

                                <Grid container sx={{paddingTop: '0 !important', paddingRight: '1em'}}>

                                    <Grid item xs={12} container spacing={1}
                                          sx={{overflow: 'auto'}}>
                                        <Grid item xs={12} container spacing={1} sx={{height: 'fit-content'}} flexWrap={'nowrap'}>
                                            {data.extracted_evidences.filter(evidence => evidence.extraction_type !== 'CURATED').map(evidence => (
                                                <ExtractionItem onClick={() => handleSelect(evidence)}
                                                                evidence={evidence}
                                                                selected={evidence.evidence_id === selectedEvidence?.evidence_id}/>
                                            ))}
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container columnSpacing={2} sx={{paddingBottom:'2em'}}>
                                        {/*<Grid item xs={1.6} container alignItems={'center'}>*/}
                                        {/*    <Grid item>*/}
                                        {/*        <Typography sx={{fontWeight: 700}}>Type</Typography>*/}
                                        {/*    </Grid>*/}
                                        {/*</Grid>*/}
                                        <Grid item xs={6} container justifyContent={'space-between'}
                                              alignItems={'center'}>
                                            <Grid item>
                                                <Typography sx={{fontWeight: 700}}>Extracted Evidence</Typography>
                                            </Grid>
                                            <Grid item>
                                                <CustomTooltip
                                                    title={viewOriginal ? 'View formatted' : 'View original'}>
                                                    <IconButton onClick={handleView}><ChangeCircleIcon/></IconButton>
                                                </CustomTooltip>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6} container alignItems={'center'}>
                                            <Grid item>
                                                <Typography sx={{fontWeight: 700}}>Curate Evidence</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container spacing={2}>

                                        <Grid item xs={6} container spacing={1}
                                              sx={{overflow: 'auto', height: 'calc(100vh - 296px)'}}>
                                            {!viewOriginal &&
                                                <Grid item xs={12} container spacing={1} sx={{height: 'fit-content'}}>
                                                    {selectedEvidence && <EvidenceDetails
                                                        extracted_evidences={selectedEvidence.extracted_evidences}/>}
                                                </Grid>}
                                            {viewOriginal && <Grid item xs={12}>
                                                <ReactJson src={selectedEvidence.extracted_evidences} name={false}
                                                           displayDataTypes={false}
                                                           enableClipboard={false}/>
                                            </Grid>
                                            }
                                        </Grid>

                                        <Grid item xs={6} sx={{overflow: 'auto', height: 'calc(100vh - 296px)'}}
                                              container
                                              spacing={0.5}>
                                            {status === 'error' && <Grid item xs={12} container spacing={1}>
                                                <AlertCard severity={'error'} message={'Failed to create evidence'}
                                                           height={'auto'} marginRight={0}/>
                                            </Grid>}
                                            <EvidenceForm formRef={formRef} onSubmit={handleSubmit}
                                                          evidence={curatedEvidence?.extracted_evidences}/>
                                        </Grid>
                                    </Grid>

                                </Grid>

                            </ResizableContainer>
                        </Grid>

                        <>{drawerOpen &&
                            <ViewPdfDrawer open={drawerOpen} onclose={handleDrawerClose} width={'50vw'}/>}</>
                    </Grid>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
        ;
};

export default ReviewEvidenceDialog;