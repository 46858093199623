import React from 'react';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import Grid from '@mui/material/Grid';
import {Button, Checkbox, FormControlLabel, FormGroup} from '@mui/material';
import {Formik} from 'formik';
import FormControl from '@mui/material/FormControl';

import LabeledCustomSelect from 'components/elements/LabeledCustomSelect';

const FilterForm = ({
                        formRef,
                        onSubmit,
                        filters
                    }) => {

    const {t} = useTranslation();

    const validationSchema = Yup.object().shape({});

    const validated = [
        {
            id: 'RELEVANT',
            name: 'Relevant',
            value: 'RELEVANT',
        },
        {
            id: 'NOT_RELEVANT',
            name: 'Not relevant',
            value: 'NOT_RELEVANT',
        },
        {
            id: 'NOT_EVALUATED',
            name: 'Not validated',
            value: 'NOT_EVALUATED',
        },
    ]

    const prediction = [
        {
            id: 'RELEVANT',
            name: 'Relevant',
            value: 'RELEVANT',
        },
        {
            id: 'NOT_RELEVANT',
            name: 'Not relevant',
            value: 'NOT_RELEVANT',
        },
        {
            id: 'NOT_EVALUATED',
            name: 'Not evaluated',
            value: 'NOT_EVALUATED',
        },
    ]

    return (<Formik
            initialValues={{
                is_downloaded: !!filters['is_downloaded'],
                with_abstract: !!filters['with_abstract'],
                is_relevant_paper: !!filters['is_relevant_paper'],
                cml: filters['cml']??'',
                gpt: filters['gpt']??'',
                manually_validated: filters['manually_validated']??'',
            }}
            innerRef={formRef}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({
                  handleChange,
                  values,
                  touched,
                  errors,
                  setFieldValue,
                  handleSubmit
              }) => (
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <FormControl variant="outlined" fullWidth>
                            <FormGroup>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            sx={{marginLeft: 0}}
                                            control={
                                                <Checkbox checked={values.is_downloaded}
                                                          onChange={(e) => setFieldValue('is_downloaded', e.target.checked)}
                                                          name="gilad"/>
                                            }
                                            labelPlacement="start"
                                            label="Show papers with PDFs only"
                                        />
                                    </Grid>


                                </Grid>
                            </FormGroup>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <FormControlLabel
                            sx={{marginLeft: 0}}
                            control={
                                <Checkbox checked={values.with_abstract}
                                          onChange={(e) => setFieldValue('with_abstract', e.target.checked)}
                                          name="gilad"/>
                            }
                            labelPlacement="start"
                            label="Show papers with abstract only"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            sx={{marginLeft: 0}}
                            control={
                                <Checkbox checked={values.is_relevant_paper}
                                          onChange={(e) => setFieldValue('is_relevant_paper', e.target.checked)}
                                          name="gilad"/>
                            }
                            labelPlacement="start"
                            label="Show relevant papers only"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <LabeledCustomSelect
                            placeholder={t('Manually validated')}
                            label={t('Manually validated')} p
                            id="select-manually_validated"
                            name="manually_validated"
                            InputLabelProps={{shrink: false}}
                            selectItems={validated}
                            height={"calc(100vh/2)"}
                            onClear={() => setFieldValue('manually_validated', '')}
                            value={values.manually_validated}
                            error={Boolean(touched.manually_validated && errors.manually_validated)}
                            helperText={touched.manually_validated && errors.manually_validated}
                            onChange={(e) => setFieldValue('manually_validated', e.target.value)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <LabeledCustomSelect
                            placeholder={t('Classic ML Model')}
                            label={t('Classic ML Model')} p
                            id="select-cml"
                            name="cml"
                            InputLabelProps={{shrink: false}}
                            selectItems={prediction}
                            onClear={() => setFieldValue('cml', '')}
                            height={"calc(100vh/2)"}
                            value={values.cml}
                            error={Boolean(touched.cml && errors.cml)}
                            helperText={touched.cml && errors.cml}
                            onChange={(e) => setFieldValue('cml', e.target.value)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <LabeledCustomSelect
                            placeholder={t('GPT')}
                            label={t('GPT')} p
                            id="select-gpt"
                            name="gpt"
                            InputLabelProps={{shrink: false}}
                            selectItems={prediction}
                            onClear={() => setFieldValue('gpt', '')}
                            height={"calc(100vh/2)"}
                            value={values.gpt}
                            error={Boolean(touched.gpt && errors.gpt)}
                            helperText={touched.gpt && errors.gpt}
                            onChange={(e) => setFieldValue('gpt', e.target.value)}
                        />
                    </Grid>

                    <Grid item xs={12} container justifyContent={'flex-end'}>
                        <Grid item>
                            <Button variant="contained" onClick={handleSubmit}>Apply</Button>
                        </Grid>
                    </Grid>

                </Grid>
            )}
        </Formik>
    );
};

export default FilterForm;