import React from 'react';
import {useTranslation} from 'react-i18next';

import PageContainer from 'components/elements/PageContainer';
import Box from "@mui/material/Box";
import {Card, Tab, Tabs} from "@mui/material";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import ManageVerticals from "./manageVerticals";

const Settings = () => {
    const {t} = useTranslation();

    return (
        <PageContainer pageTitle={t('misc.settings.pageTitle')} pageSubTitle={t('misc.settings.pageSubTitle')}>
            <SettingsPage/>
        </PageContainer>
    );
};

function CustomTabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{padding: '1em 0 0 0'}}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const SettingsPage = ({containerHeight}) => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Grid container flexWrap="nowrap" spacing={1} sx={{padding: '1em 1em 0 1em !important', height: '100%'}}>
            <Grid item xs={12} sx={{height: '100%'}}>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Manage Verticals" {...a11yProps(0)} sx={{textTransform: 'none'}}/>
                    </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                    <ManageVerticals containerHeight={containerHeight}/>
                </CustomTabPanel>
            </Grid>
        </Grid>
    );
};

export default Settings;
