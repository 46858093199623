import React, {useEffect, useRef, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import {useTranslation} from 'react-i18next';
import {Button, Checkbox, FormControlLabel} from '@mui/material';
import {useSelector} from 'react-redux';

import useScoreClassifierTrigger from 'hooks/classifiers/useScoreClassifierTrigger';
import useClassifierModels from "hooks/classifiers/useClassifierModels";

import ClassifierForm from './classifier-form';

const TriggerClassifierDialog = ({open, handleClose, total = 0}) => {
    const {t} = useTranslation();
    const formRef = useRef();
    const [verticals, setVerticals] = useState([]);
    const [models, setModels] = useState([]);
    const [selectedModel, setSelectedModel] = useState('');
    const [navigate, setNavigate] = useState(true);

    const initData = useSelector((state) => state.app.initData);
    const selectedVertical = useSelector((state) => state.app.vertical);

    const {
        data
    } = useClassifierModels({
        filters: {},
    });

    useEffect(() => {
        if (initData) {
            setVerticals(initData.verticals.map((vertical) => {
                return {
                    id: vertical.vertical,
                    name: vertical.vertical_name,
                    value: vertical.vertical,
                };
            }))
        }
    }, [initData])

    useEffect(() => {
        if (data) {
            const list = (data.pages.reduce((accum, curr) => {
                return [...accum, ...curr.data];
            }, []))
            setModels(list.map((model) => {
                return {
                    id: model.model_id,
                    name: model.model_name,
                    value: model.model_id,
                };
            }))
            setSelectedModel(list[0].model_id)
        }
    }, [data])

    const {mutateAsync, status} = useScoreClassifierTrigger();

    const handleTrigger = () => {
        formRef.current.handleSubmit();
    }

    const handleSubmit = (values) => {
        const req = {
            vertical: selectedVertical,
            classification_run_name: values.classification_run_name,
            model_id: values.model_id,
            classification_configs: [
                {
                    type: "CLASSIC_ML_MODEL",
                    reclassify_all_papers: false,
                    classify: false
                },
                {
                    type: "OPENAI_MODEL",
                    reclassify_all_papers: false,
                    classify: false
                }

            ]
        }

        for (const classificationType of values.classification_types) {
            if (classificationType === 'CLASSIC_ML_MODEL') {
                req.classification_configs[0].classify = true
            }

            if (classificationType === 'OPENAI_MODEL') {
                req.classification_configs[1].classify = true
                req.classification_configs[1].reclassify_all_papers = values.reclassify_all_papers
            }
        }

        mutateAsync({req}).then(() => {
            handleClose({navigate})
        });
    }

    const getVerticalName = () => {
        const index = verticals.findIndex(v => v.value === selectedVertical)
        if (index >= 0) {
            return verticals[index].name
        } else {
            return ''
        }
    }

    const handleChangeNavigate = () => {
        setNavigate(prevState => !prevState)
    }

    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth
                maxWidth={'md'}
            >
                <DialogContent sx={{padding: '2rem', minHeight: '10vh'}}>
                    <Stack direction='row' spacing={1} justifyContent='flex-end'>
                        <IconButton
                            aria-label='close'
                            onClick={handleClose}
                            sx={{
                                backgroundColor: '#333333',
                                padding: '4px',
                                '&:hover': {
                                    backgroundColor: '#333333',
                                },
                            }}
                        >
                            <CloseIcon sx={{color: '#ffffff', fontSize: '1.2rem'}}/>
                        </IconButton>
                    </Stack>
                    <Grid container spacing={4}>
                        <Grid item xs={11} container spacing={2}>
                            <Grid item xs={12}
                                  sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                <Typography sx={{
                                    fontWeight: '700',
                                    fontSize: '1.2rem'
                                }}>{t(`Classify Papers in ${getVerticalName()}`)}</Typography>
                            </Grid>
                            <Grid item xs={12} sx={{paddingTop: '4px !important'}}>
                                <Divider sx={{borderBottom: '2px solid black;', width: '100%'}}/>
                            </Grid>
                        </Grid>

                        <Grid item xs={11} container spacing={2}>
                            <Grid item xs={12}>
                                <Typography sx={{fontSize:'0.875rem',fontWeight:600}}> Number of papers selected for classification: {total}</Typography>
                            </Grid>
                            <ClassifierForm formRef={formRef} onSubmit={handleSubmit} vertical={getVerticalName()}
                                            selectedModel={selectedModel} models={models}
                                            selectedVertical={selectedVertical}/>
                        </Grid>

                        <Grid item xs={11} container justifyContent={'flex-end'} spacing={2}>
                            <Grid item>
                                <FormControlLabel
                                    sx={{marginLeft: 0}}
                                    control={
                                        <Checkbox checked={navigate}
                                                  onChange={(e) => handleChangeNavigate()}
                                                  name="gilad"/>
                                    }
                                    labelPlacement="start"
                                    label="Navigate to classification sessions on submit"
                                />
                            </Grid>
                            <Grid item>
                                <Button variant="outlined" disabled={status === 'loading'}
                                        onClick={handleClose}>Cancel</Button>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" disabled={status === 'loading'}
                                        onClick={handleTrigger}>Classify</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
};

export default TriggerClassifierDialog;