import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Grid from '@mui/material/Grid';
import UploadIcon from '@mui/icons-material/Upload';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import RefreshIcon from '@mui/icons-material/Refresh';
import PostAddIcon from '@mui/icons-material/PostAdd';
import {useNavigate} from 'react-router-dom';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import Typography from '@mui/material/Typography';

import PageContainer from 'components/elements/PageContainer';
import CustomButton from 'components/elements/CustomButton';
import {
    ROUTE_PIPELINES_CLASSIFICATION_SESSIONS,
    ROUTE_PIPELINES_DOWNLOAD_SESSIONS,
    ROUTE_PIPELINES_MODEL_TRAINING_SESSIONS
} from 'pages/constants';

import Filters from './filters';
import CreatePaperDialog from './createPaper/create-paper-dialog';
import CreateSearchDialog from './createSearch/create-search-dialog';
import TriggerClassifierDialog from './triggerClassifier/trigger-classifier-dialog';
import ImportPaperDialog from './importPaperWithPDF/import-paper-dialog';
import PaperList from './paper-list';
import TrainClassifierDialog from './trainClassifier/train-classifier-dialog';
import {notifyChangePapersFilters, notifyChangeVertical} from "../../../../state/app";


const InboxExplore = () => {
    const {t} = useTranslation();

    return (
        <PageContainer pageTitle={t('evidence.explore')} pageSubTitle={t('Manage Downloaded Papers')}>
            <InboxExplorePage/>
        </PageContainer>
    );
};

const predictionStatues = ['RELEVANT', 'NOT_RELEVANT', 'NOT_EVALUATED']

const InboxExplorePage = ({containerHeight}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const [paperAdding, setPaperAdding] = useState(false);
    const [searchAdding, setSearchAdding] = useState(false);
    const [triggering, setTriggering] = useState(false);
    const [importing, setImporting] = useState(false);
    const [training, setTraining] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [total, setTotal] = useState(0);

    const handleAdd = () => {
        setPaperAdding(true)
    }
    const handleAddPaperClose = () => {
        setPaperAdding(false)
    }
    const handleAddSearch = () => {
        setSearchAdding(true)
    }
    const handleAddSearchClose = ({navigate = false}) => {
        setSearchAdding(false);
        if (navigate) {
            handleGoToDownloadSessions();
        }
    }
    const handleTrigger = () => {
        setTriggering(true)
    }
    const handleTrain = () => {
        setTraining(true)
    }
    const handleImportWithPDF = () => {
        setImporting(true)
    }

    const handleTriggerClose = ({navigate = false}) => {
        setTriggering(false)
        if (navigate) {
            handleGoToClassificationSessions();
        }
    }
    const handleImportClose = ({navigate = false}) => {
        setImporting(false)
        if (navigate) {
            handleGoToDownloadSessions();
        }
    }

    const handleTrainClose = ({navigate = false}) => {
        setTraining(false)
        if (navigate) {
            handleGoToModelTrainingSessions();
        }
    }

    const handleRefresh = () => {
        setRefresh(Math.random())
    }

    const handleGoToDownloadSessions = () => {
        navigate({
            pathname: ROUTE_PIPELINES_DOWNLOAD_SESSIONS
        });
    };

    const handleGoToModelTrainingSessions = () => {
        navigate({
            pathname: ROUTE_PIPELINES_MODEL_TRAINING_SESSIONS
        });
    };

    const handleGoToClassificationSessions = () => {
        navigate({
            pathname: ROUTE_PIPELINES_CLASSIFICATION_SESSIONS
        });
    };

    const handleTotal = (value) => {
        setTotal(value)
    }

    return (
        <Grid container flexDirection="column" flexWrap="nowrap" spacing={1} sx={{padding: '0 1em !important'}}>

            <Grid item container xs={12} alignItems={'center'}>
                <Filters/>
            </Grid>

            <Grid item container xs={12} justifyContent={'space-between'}>
                <Grid item container xs={6} spacing={3}>
                    <Grid item>
                        <CustomButton sx={{color: '#656565'}} startIcon={<ModelTrainingIcon sx={{color: '#49ac43'}}/>}
                                      disableRipple
                                      onClick={handleTrain}>{t('Train Classifier')}</CustomButton>
                    </Grid>
                    <Grid item>
                        <CustomButton sx={{color: '#656565'}} startIcon={<PlayArrowIcon sx={{color: '#49ac43'}}/>}
                                      disableRipple
                                      onClick={handleTrigger}>{t('Classify Papers')}</CustomButton>
                    </Grid>
                </Grid>
                <Grid item container xs={6} justifyContent={'flex-end'} spacing={3}>
                    <Grid item>
                        <CustomButton sx={{color: '#656565'}} startIcon={<ManageSearchIcon/>} disableRipple
                                      onClick={handleAddSearch}>{t('inbox.newSearch')}</CustomButton>
                    </Grid>
                    <Grid item>
                        <CustomButton sx={{color: '#656565'}} disableRipple
                                      startIcon={<UploadIcon/>}
                                      onClick={handleImportWithPDF}>{t('Import URLs')}</CustomButton>
                    </Grid>

                    {/*<Grid item>*/}
                    {/*    <MuiButton sx={{color: '#656565'}}*/}
                    {/*               startIcon={<UploadIcon/>}>{t('Import CSV')}</MuiButton>*/}
                    {/*</Grid>*/}
                    <Grid item>
                        <CustomButton sx={{color: '#656565'}} startIcon={<PostAddIcon/>}
                                      onClick={handleAdd}>{t('New Paper')}</CustomButton>
                    </Grid>

                    <Grid item>
                        <CustomButton sx={{color: '#656565'}} startIcon={<RefreshIcon sx={{color: '#0794d3'}}/>}
                                      disableRipple
                                      onClick={handleRefresh}>{t('Refresh')}</CustomButton>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <PaperList height={containerHeight - 164}
                           refresh={refresh}
                           handleTotal={handleTotal}
                />
            </Grid>
            <Grid item xs={12} container justifyContent={'flex-end'}>
                <Grid item>
                    <Typography sx={{fontWeight:600,fontSize:'0.875rem'}}>Paper Count: {total}</Typography>
                </Grid>
            </Grid>
            {paperAdding &&
                <CreatePaperDialog open={paperAdding} handleClose={handleAddPaperClose} height={containerHeight}/>}
            {searchAdding &&
                <CreateSearchDialog open={searchAdding} handleClose={handleAddSearchClose} height={containerHeight}/>}
            {triggering &&
                <TriggerClassifierDialog open={triggering} handleClose={handleTriggerClose} height={containerHeight} total={total}/>}
            {importing &&
                <ImportPaperDialog open={importing} handleClose={handleImportClose} height={containerHeight}/>}
            {training &&
                <TrainClassifierDialog open={training} handleClose={handleTrainClose} height={containerHeight}/>}
        </Grid>
    );
}

export default InboxExplore;
