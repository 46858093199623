import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Grid from '@mui/material/Grid';
import {useSelector} from 'react-redux';

import usePapers from 'hooks/papers/usePapers';
import LoaderSpinner from 'components/elements/LoaderSpinner';
import CustomInfiniteLoadTable from 'components/elements/CustomInfiniteLoadTable';

import PaperRow from './paper-row';

const PaperList = ({
                       height, refresh = 0, handleTotal = () => {
    }
                   }) => {
    const {t} = useTranslation();
    const selectedVertical = useSelector((state) => state.app.vertical);
    const filters = useSelector((state) => state.app.papersFilters);

    const [flattenedResults, setFlattenedResults] = React.useState([]);

    const [sort, setSort] = useState({
        relevant_paper_model_prediction_score: 'desc',
        citation_count: 'desc',
        published_year: 'desc',
        created_at: 'desc',
        current: 'created_at'
    });

    const {data, isFetchingNextPage, hasNextPage, fetchNextPage, isError, status, refetch, isFetched} = usePapers({
        filters,
        sort,
        vertical: selectedVertical,
        enabled: selectedVertical !== ''
    });

    useEffect(() => {
        if (data) {
            setFlattenedResults(data.pages.reduce((accum, curr) => {
                return [...accum, ...(curr.data ?? [])];
            }, []))
            if (data.pages.length > 0) {
                handleTotal(data.pages[0].meta['total_count'])
            } else {
                handleTotal(0)
            }
        }
    }, [data])

    useEffect(() => {
        refetch()
    }, [refresh])

    const columns = useMemo(
        () => [
            {
                Header: t('inbox.paperName'),
                accessor: 'paper_name',
                align: 'left',
                width: '30%',
                minWidth:'432px',
                sortable: false,
                sticky: true,
            },
            {
                Header: t('inbox.citations'),
                accessor: 'citation_count',
                align: 'right',
                width: '7%',
                minWidth:'100px',
                sortable: true,
                sticky: false,
            },
            {
                Header: t('Published'),
                accessor: 'published_year',
                align: 'right',
                width: '7%',
                minWidth:'100px',
                sortable: true,
                sticky: false,
            },
            {
                Header: t('Classification'),
                accessor: 'relevant_paper_model_prediction_score',
                align: 'right',
                width: '12%',
                minWidth:'172px',
                sortable: false,
                sticky: false,
            },
            {
                Header: t('Manually Validated'),
                accessor: 'relevant_paper_manually_validated',
                align: 'right',
                width: '12%',
                minWidth:'172px',
                sortable: false,
                sticky: false,
                tooltip: 'Manually validated papers'

            },
            {
                Header: t('Downloaded Date'),
                accessor: 'created_at',
                align: 'left',
                width: '12%',
                minWidth:'172px',
                sortable: true,
                sticky: false,

            },
            {
                Header: t(''),
                accessor: 'source_url',
                align: 'right',
                width: '20%',
                minWidth:'288px',
                sortable: false,
                sticky: false,
            }
        ],
        [t]
    );

    if (status === 'loading') {
        return <Grid container justifyContent="center" alignItems="center" sx={{width: '100%', height: height - 120}}>
            <Grid item>
                <LoaderSpinner type="Bars" color="#175da8" secondaryColor={"#6abed5"} height={70} width={70}/>
            </Grid>
        </Grid>
    }

    return <Grid container>
            <Grid item xs={12}>
                {flattenedResults && <CustomInfiniteLoadTable
                    height={height}
                    data={flattenedResults}
                    isFetchingNextPage={isFetchingNextPage}
                    hasNextPage={hasNextPage}
                    fetchNextPage={fetchNextPage}
                    isError={isError}
                    columns={columns}
                    sort={sort}
                    handleSort={setSort}
                    refetch={refetch}
                    itemTemplate={({data, index}) => <PaperRow data={data} index={index} refetch={refetch}/>}
                />}
            </Grid>
        </Grid>
};

export default PaperList;
