import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Grid from '@mui/material/Grid';
import {useSelector} from 'react-redux';
import Box from '@mui/material/Box';

import LoaderSpinner from 'components/elements/LoaderSpinner';
import InfiniteLoaderList from 'components/elements/InfiniteLoadList';
import useEvidenceExtractions from 'hooks/evidences/useEvidenceExtractions';

import ExtractionSessionCard from './extraction-session-card';

const SessionsList = ({
                          height, refresh = 0, filters = {
        search: '',
    }, sort = {
        name: '',
        order: '',
    }
                      }) => {
    const {t} = useTranslation();
    const [flattenedResults, setFlattenedResults] = useState([]);
    const selectedVertical = useSelector((state) => state.app.vertical);

    const {
        data,
        isFetchingNextPage,
        hasNextPage,
        fetchNextPage,
        isError,
        status,
        refetch,
    } = useEvidenceExtractions({
        filters,
        sort,
        vertical: selectedVertical,
        enabled: selectedVertical !== ''
    });

    useEffect(() => {
        refetch()
    }, [refresh])

    useEffect(() => {
        if (data) {
            setFlattenedResults(data.pages.reduce((accum, curr) => {
                return [...accum, ...curr.data];
            }, []))
        }
    }, [data])

    if (status === 'loading') {
        return <Grid container justifyContent="center" alignItems="center" sx={{width: '100%', height: height - 120}}>
            <Grid item>
                <LoaderSpinner type="Bars" color="#175da8" secondaryColor={"#6abed5"} height={70} width={70}/>
            </Grid>
        </Grid>
    }

    return <Box sx={{
        width: '100%',
        overflowX: 'auto'
    }}>
        <Grid container sx={{minWidth: '960px'}}>
            <Grid item xs={12}>
                {flattenedResults && <InfiniteLoaderList
                    height={height}
                    data={flattenedResults}
                    isFetchingNextPage={isFetchingNextPage}
                    hasNextPage={hasNextPage}
                    fetchNextPage={fetchNextPage}
                    isError={isError}
                    itemTemplate={ExtractionSessionCard}
                />}
            </Grid>
        </Grid>
    </Box>
};

export default SessionsList;
