import React from 'react';

import Grid from '@mui/material/Grid';
import Alert from "@mui/material/Alert";

const AlertCard = ({message, severity = 'info', height, marginRight = 0.5, ...rest}) => {
    return <Grid container justifyContent="center" alignItems="flex-start" item
                 sx={{width: '100%', height: height ? height : 400}}>
        <Grid item xs={12} sx={{marginRight: `${marginRight}em`}}>
            <Alert severity={severity} {...rest}>{message}</Alert>
        </Grid>
    </Grid>
};

export default AlertCard;
