import React, {useState} from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {isArray} from 'lodash-es';
import Divider from '@mui/material/Divider';
import Alert from "@mui/material/Alert";
import {Button} from "@mui/material";

const EvidenceItem = ({name, value}) => {
    return <Grid container item xs={12}>
        <Grid item xs={12}>
            <Typography sx={{
                fontSize: '0.875rem',
                fontWeight: '700',
                color: '#797979',
                textTransform: 'capitalize'
            }}>{name}</Typography>
        </Grid>
        <Grid item xs={12}>
            <Typography sx={{fontSize: '0.875rem'}}>{value}<span
                style={{paddingLeft: '0.5em', verticalAlign: 'middle', cursor: 'pointer'}}>
                <CopyToClipboard text={value}>
                    <IconButton sx={{padding: '0.1em'}}>
                <ContentCopyIcon
                    sx={{fontSize: '0.875rem'}}/>
                    </IconButton>
                </CopyToClipboard>
            </span></Typography>
        </Grid>
    </Grid>
}

const GptResponse = ({value}) => {
    const [details, setDetails] = useState(false)
    const handleViewDetails = () => {
        setDetails((prevState) => !prevState)
    }
    return <Grid container item xs={12}>
        <Grid item xs={12}>
            <Alert
                severity="warning"
                action={
                    <Button color="inherit" size="small" sx={{textTransform: 'none'}} onClick={handleViewDetails}>
                        {details ? 'Hide' : 'Details'}
                    </Button>
                }
            >
                Failed to extract evidence
            </Alert>
        </Grid>
        {details && <Grid item xs={12}>
            <Alert icon={false} severity="warning">
                {value}
            </Alert>
        </Grid>
        }
    </Grid>
}

const OutcomeItem = ({name, value}) => {
    return <Grid container item xs={12}>
        <Grid item xs={12}>
            <Typography sx={{fontSize: '0.875rem',}}>
                <span style={{
                    fontWeight: '700',
                    color: '#797979',
                    textTransform: 'capitalize'
                }}>{name}: </span>
                {value && typeof value !== 'object' && !isArray(value) &&
                    <span>
                    {value}
                        <span
                            style={{paddingLeft: '0.5em', verticalAlign: 'middle', cursor: 'pointer'}}>
                    <CopyToClipboard text={value}>
                    <IconButton sx={{padding: '0.1em'}}>
                        <ContentCopyIcon sx={{fontSize: '0.875rem'}}/>
                    </IconButton>
                    </CopyToClipboard>
                    </span>
                </span>
                }
                {typeof value === 'object' && <span>{JSON.stringify(value, null, '')}</span>}
                {isArray(value) && <span>{JSON.stringify(value, null, '')}</span>}
            </Typography>
        </Grid>
    </Grid>
}

const OutcomeRenderer = ({outcome}) => {
    const variables = [
        {
            name: 'outcome_variable',
            keys: [
                "outcome_variable", "Outcome variable", "outcome variable", "Outcome_variable", "outcome"
            ]
        },
        {
            name: 'metric_name',
            keys: [
                "metric name", "metric_name", "Metric name", "Metric_name"
            ]
        },
        {
            name: 'effect_sizes',
            keys: [
                "effect sizes", "effect_sizes", "Effect sizes", "effect_size", "Effect_size"
            ]
        },
        {
            name: 'effect_sizes_post',
            keys: [
                "effect sizes post", "effect_sizes_post", "Effect sizes post"
            ]
        },
        {
            name: 'effect_sizes_pre',
            keys: [
                "effect sizes pre", "effect_sizes_pre", "Effect sizes pre"
            ]
        },
        {
            name: 'p_value',
            keys: [
                "p_value", "p-value", "P_value"
            ]
        },
        {
            name: 'confidence_interval',
            keys: [
                "confidence interval", "confidence_interval", "Confidence_interval"
            ]
        },
    ]

    return <>
        {variables.map(variable => (<>
                {variable.name === 'outcome_variable' && variable.keys.map((key) => outcome[key] && <OutcomeItem
                    name={'Outcome Variable'} value={outcome[key]}/>)}

                {variable.name === 'metric_name' && variable.keys.map((key) => outcome[key] && <OutcomeItem
                    name={'Metric Name'} value={outcome[key]}/>)}

                {variable.name === 'effect_sizes' && variable.keys.map((key) => outcome[key] && <OutcomeItem
                    name={'Effect Sizes'} value={outcome[key]}/>)}

                {variable.name === 'effect_sizes_post' && variable.keys.map((key) => outcome[key] && <OutcomeItem
                    name={'Effect Sizes Post'} value={outcome[key]}/>)}

                {variable.name === 'effect_sizes_pre' && variable.keys.map((key) => outcome[key] && <OutcomeItem
                    name={'Effect Sizes Pre'} value={outcome[key]}/>)}

                {variable.name === 'p_value' && variable.keys.map((key) => outcome[key] && <OutcomeItem
                    name={'P Value'} value={outcome[key]}/>)}

                {variable.name === 'confidence_interval' && variable.keys.map((key) => outcome[key] &&
                    <OutcomeItem
                        name={'Confidence Interval'} value={outcome[key]}/>)}
            </>)
        )}
        <Grid item xs={12}>
            <Divider sx={{borderBottom: '0.15em solid rgb(233, 233, 233)'}}/>
        </Grid>
    </>
}

const OutcomeMetrics = ({data}) => {

    return <Grid container item xs={12} spacing={2}>
        <Grid item xs={12}>
            <Typography sx={{
                fontSize: '0.875rem',
                fontWeight: '700',
                color: '#797979',
                textTransform: 'capitalize'
            }}>Outcome Metrics</Typography>
        </Grid>
        <Grid item xs={12} container spacing={1}>
            {isArray(data) && data.map((outcome) => {
                return <OutcomeRenderer outcome={outcome}/>
            })}
            {typeof data === 'object' && !isArray(data) && <OutcomeRenderer outcome={data}/>}
        </Grid>
    </Grid>
}

const EvidenceDetails = ({extracted_evidences}) => {
    const keys = ['research_objective', 'study_design', 'treatment_used', 'population_characteristics', 'population', 'outcome_metrics']

    return <Grid item xs={12} container spacing={1} sx={{height: 'fit-content'}}>
        {isArray(extracted_evidences) && extracted_evidences.map(evidence => (Object.keys(evidence).map((key, index) => {
                if (key === 'GPT Response') {

                } else {
                    return <Grid item xs={12}>
                        <EvidenceItem name={key} value={evidence[key]}/>
                    </Grid>
                }

            })
        ))}

        {!isArray(extracted_evidences) &&
            <>
                {Object.keys(extracted_evidences).includes('GPT Response') ?
                    <GptResponse value={extracted_evidences['GPT Response']}/> :
                    <>
                        {
                            extracted_evidences['research_objective'] &&
                            <EvidenceItem name={'Objective'}
                                          value={extracted_evidences['research_objective']}/>
                        }
                        {
                            extracted_evidences['study_design'] &&
                            <EvidenceItem name={'Study Design'}
                                          value={extracted_evidences['study_design']}/>
                        }
                        {
                            extracted_evidences['treatment_used'] &&
                            <EvidenceItem name={'Treatment'}
                                          value={extracted_evidences['treatment_used']}/>
                        }
                        {
                            extracted_evidences['population_characteristics'] &&
                            <EvidenceItem name={'Population Characteristics'}
                                          value={extracted_evidences['population_characteristics']}/>
                        }
                        {
                            extracted_evidences['population'] &&
                            <EvidenceItem name={'Population'}
                                          value={extracted_evidences['population']}/>
                        }

                        {
                            extracted_evidences['outcome_metrics'] &&
                            <OutcomeMetrics
                                data={extracted_evidences['outcome_metrics']}/>
                        }

                        {Object.keys(extracted_evidences).map((key, index) => {
                            return <>
                                {typeof extracted_evidences[key] !== 'object' && extracted_evidences[key] !== null && !keys.includes(key)
                                    && <Grid item xs={12}>
                                        <EvidenceItem name={key}
                                                      value={extracted_evidences[key]}/>
                                    </Grid>}
                            </>
                        })}
                    </>
                }
            </>
        }
    </Grid>
}

export default EvidenceDetails