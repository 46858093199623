import React, {useEffect, useState} from 'react';
import WebViewer from '@pdftron/pdfjs-express-viewer';

import usePaperDownload from 'hooks/papers/usePaperDownload';

const PdfViewer = ({paperId, height = 'calc(100vh - 80px)',viewer}) => {
    const [instance, setInstance] = useState(null)

    const {data, status} = usePaperDownload({paperId});

    useEffect(() => {
        if (data && !instance) {
            WebViewer(
                {
                    path: '/pdfjs-express',
                    initialDoc: '',
                    licenseKey: 'mSrKc5CHYIPhqt9iLC5I'
                },
                viewer.current,
            ).then((instance) => {
                setInstance(instance)
                instance.UI.loadDocument(data.data, {filename: data.filename});
                instance.setFitMode(instance.FitMode.FitWidth);
            });
        }
        if(data && instance){
            instance.UI.loadDocument(data.data, {filename: data.filename});
            instance.setFitMode(instance.FitMode.FitWidth);
        }
    }, [data])

    // if (status === 'pending') {
    //     return <Grid container justifyContent="center" alignItems="center"
    //                  sx={{width: '100%', height: 'calc(100vh - 224px)'}}>
    //         <Grid item>
    //             <LoaderSpinner type="Bars" color="#175da8" secondaryColor={'#6abed5'} height={70}
    //                            width={70}/>
    //         </Grid>
    //     </Grid>
    // }

    return (
        <div className="MyComponent">
            <div className="webviewer" style={{height}} ref={viewer}></div>
        </div>
    );

}

export default PdfViewer;