import React from 'react';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import Grid from '@mui/material/Grid';
import {
    TextField
} from '@mui/material';
import {Formik} from 'formik';

const VerticalForm = ({
                          formRef,
                          onSubmit,
                          edit = false,
                          paper,
                      }) => {

    const {t} = useTranslation();

    const validationSchema = Yup.object().shape({
        vertical_name: Yup.string().required(t('Vertical name is required')),
        vertical: Yup.string().required(t('Vertical is required')),
    });

    return (
        <Grid conainer item xs={12}>
            <Formik
                initialValues={{
                    vertical_name: edit ? paper['vertical_name'] : '',
                    vertical: edit ? paper['vertical'] : '',
                }}
                innerRef={formRef}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({
                      handleChange,
                      values,
                      touched,
                      errors,
                      setFieldValue
                  }) => (
                    <Grid container spacing={2}>

                        <Grid item xs={12}>
                            <TextField id="name"
                                       label={t("Vertical")}
                                       required
                                       name="vertical"
                                       value={values.vertical}
                                       onChange={handleChange}
                                       error={Boolean(touched.vertical && errors.vertical)}
                                       helperText={touched.vertical && errors.vertical}
                                       fullWidth
                                       variant="outlined" size="small"/>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField id="name"
                                       label={t("Vertical Name")}
                                       required
                                       name="vertical_name"
                                       value={values.vertical_name}
                                       onChange={handleChange}
                                       error={Boolean(touched.vertical_name && errors.vertical_name)}
                                       helperText={touched.vertical_name && errors.vertical_name}
                                       fullWidth
                                       variant="outlined" size="small"/>
                        </Grid>


                    </Grid>
                )}
            </Formik>
        </Grid>
    );
};

export default VerticalForm;