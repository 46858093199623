import React, {useState} from 'react';
import styled from '@mui/system/styled';
import Grid from '@mui/material/Grid';
import {useTranslation} from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import WarningIcon from '@mui/icons-material/Warning';
import EditIcon from '@mui/icons-material/Edit';
import Link from '@mui/material/Link';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Typography from '@mui/material/Typography';
import {format, parseISO} from 'date-fns';
import MuiTableCell from '@mui/material/TableCell';
import {Popover} from '@mui/material';
import Box from '@mui/material/Box';

import usePaperUpdateValidated from 'hooks/papers/usePaperUpdateValidated';
import CustomTooltip from 'components/elements/CustomTooltip';
import LabeledChip from 'components/elements/LabeledChip';
import CustomButton from 'components/elements/CustomButton';
import ConfirmationDialog from 'components/elements/ConfirmationDialog';
import ViewPdfDrawer from 'components/elements/viewPdfDrawer';
import usePaperDelete from 'hooks/papers/usePaperDelete';

import EditPaperDialog from './updatePaper/edit-paper-dialog';
import ViewPaperDrawer from './viewPaperDrawer';
import ActionMenu from './ActionMenu';

const TableCell = styled(MuiTableCell)(({eligible = false}) => ({
    fontSize: '0.875rem',
    background: '#ffffff',
    ...(eligible && {backgroundColor: '#fffbf4'})
}));

const ClassifyChip = ({classify}) => {
    const {t} = useTranslation();

    switch (classify) {
        case 'RELEVANT':
            return <LabeledChip cursor={'pointer'} label={`${t('Relevant')}`}
                                backgroundColor={'#daf7ca'}/>;
        case 'NOT_RELEVANT':
            return <LabeledChip cursor={'pointer'} label={`${t('Not relevant')}`}
                                backgroundColor={'#efefcb'}/>;
        case 'NOT_EVALUATED':
            return <LabeledChip label={`${t('Not Evaluated')}`}
                                backgroundColor={'#c8edfd'}/>;
        default:
            return ''
    }
}

const ClassificationTooltip = ({types}) => {
    const {t} = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const getClassificationStatus = ({types=[]}) => {
        let classify = 'NOT_EVALUATED';
        if (types) {
            if (types.some(item => item.prediction_flag === "RELEVANT")) {
                classify = "RELEVANT"
            }

            if (types.length && types.every(item => item.prediction_flag === "NOT_RELEVANT")) {
                classify = "NOT_RELEVANT"
            }
        }

        return classify
    }



    return (
        <div>
            <Box
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                sx={{pointer:'cursor'}}
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
            >
                <ClassifyChip classify={getClassificationStatus({types})}/>
            </Box>
            {getClassificationStatus({types}) !== 'NOT_EVALUATED' &&
                <Popover
                    id="mouse-over-popover"
                    sx={{
                        pointerEvents: 'none',
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                >
                    <Grid container spacing={2} p={1} sx={{maxWidth: '300px'}}>
                        {types && types.map((type) => {
                            return <Grid item container xs={12}>
                                <Grid item xs={12}>
                                    <Typography
                                        sx={{fontSize: '0.875rem'}}> Type: {type['classification_type'] === 'CLASSIC_ML_MODEL' ? 'Classic ML model' : 'GPT'}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography
                                        sx={{fontSize: '0.875rem'}}>Prediction: <ClassifyChip classify={type['prediction_flag']}/></Typography>
                                </Grid>
                                {type['classification_type'] === 'CLASSIC_ML_MODEL' && <Grid item xs={12}>
                                    <Typography sx={{fontSize: '0.875rem'}}>Prediction
                                        score: {type['prediction_score'].toFixed(3)}</Typography>
                                </Grid>}
                            </Grid>
                        })}
                    </Grid>
                </Popover>
            }
        </div>
    );
}


const PaperRow = ({
                      data, index, refetch = () => {
        }
                  }) => {
        const {t} = useTranslation();
        const [anchorEl, setAnchorEl] = React.useState(null);
        const open = Boolean(anchorEl);
        const [drawerOpen, setDrawerOpen] = useState(null);
        const [paperEditing, setPaperEditing] = useState(null);
        const [paperViewing, setPaperViewing] = useState(false);
        const [paperDeleting, setPaperDeleting] = useState(null);
        const paperEditingOpen = Boolean(paperEditing);
        const paperViewingOpen = Boolean(paperViewing);
        const paperDeleteOpen = Boolean(paperDeleting);

        const {mutateAsync, status} = usePaperUpdateValidated();
        const {mutateAsync: deletePaper, status: deleteStatus} = usePaperDelete();
        const handleSubmit = (value) => {
            const req = {
                "relevant_paper_manually_validated": value
            }
            mutateAsync({req, paperId: data.paper_id}).then(() => {
                handleClose()
                refetch()
            });
        }

        const getManuallyValidated = ({type, key}) => {
            switch (type) {
                case 'RELEVANT':
                    return <LabeledChip label={`${t('Relevant')}`}
                                        backgroundColor={'#daf7ca'}/>;
                case 'NOT_RELEVANT':
                    return <LabeledChip label={`${t('Not relevant')}`}
                                        backgroundColor={'#efefcb'}/>;
                case 'NOT_EVALUATED':
                    return <LabeledChip label={`${t('Not Validated')}`}
                                        backgroundColor={'#ffbd6a'}/>;
                default:
                    return type
            }
        }

        const handleClick = (event) => {
            event.stopPropagation();
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        const handleEdit = (event) => {
            event.stopPropagation();
            setPaperEditing(data)
        }

        const handleEditPaperClose = () => {
            setPaperEditing(null);
            refetch()
        }

        const handleView = (event) => {
            event.stopPropagation();
            setPaperViewing(data)
        }

        const handleDeleteOpen = (event) => {
            event.stopPropagation();
            setPaperDeleting(data)
        }

        const handleDeleteClose = () => {
            setPaperDeleting(null)
        }

        const handleViewPaperClose = () => {
            setPaperViewing(null);
            handleClose();
        }

        const getCitationCount = (count) => {
            if (count) {
                return count
            } else {
                if (count === 0) {
                    return count
                } else {
                    return 'N/A'
                }
            }
        }

        const handleDrawerOpen = (event) => {
            event.stopPropagation();
            setDrawerOpen(data['paper_id'])
        }

        const handleDrawerClose = () => {
            setDrawerOpen(null)
        }

        const paperStatus = () => {
            if (data['paper_downloaded_path'] === '' && data['abstract'] === '') {
                return 'NOT_ELIGIBLE'
            } else if (data['paper_downloaded_path'] === '') {
                return 'NO_PDF'
            } else if (data['abstract'] === '') {
                return 'NO_ABSTRACT'
            } else {
                return 'ELIGIBLE'
            }
        }

        const paperStatusTooltip = () => {
            if (data['paper_downloaded_path'] === '' && data['abstract'] === '') {
                return 'Pdf and abstract not available'
            } else if (data['paper_downloaded_path'] === '') {
                return 'No Pdf available'
            } else if (data['abstract'] === '') {
                return 'No abstract available'
            } else {
                return ''
            }
        }

        const handleDelete = (paperId) => {
            deletePaper({paperId}).then(() => {
                handleDeleteClose();
                refetch();
            });
        }

        return (
            <>
                {/*<TableRow sx={{*/}
                {/*    ...(paperStatus() !== 'ELIGIBLE' && {backgroundColor: alpha('#ffe2b7', 0.15)}),*/}
                {/*}}>*/}
                <TableCell sx={{position: 'sticky', left: 0, zIndex: 1}} eligible={paperStatus() !== 'ELIGIBLE'}>
                        <span>{data['paper_name']}<span
                            style={{paddingLeft: '0.5em', verticalAlign: '-0.25em'}}>{paperStatus() !== 'ELIGIBLE' &&
                            <CustomTooltip
                                title={`${paperStatusTooltip()}. Paper is not eligible for evidence extraction`}><WarningIcon
                                fontSize="small"
                                sx={{
                                    color: '#eca749',
                                    ".& MuiSvgIcon-root": {fontSize: '0,875rem'}
                                }}/></CustomTooltip>}</span></span>
                </TableCell>
                <TableCell width={0.8} align={'right'} eligible={paperStatus() !== 'ELIGIBLE'}>
                    {getCitationCount(data.citation_count)}
                </TableCell>
                <TableCell width={0.8} align={'right'} eligible={paperStatus() !== 'ELIGIBLE'}>
                    {data.published_year ? data.published_year : 'N/A'}
                </TableCell>
                <TableCell width={2.0} align={'right'} eligible={paperStatus() !== 'ELIGIBLE'}>
                    <ClassificationTooltip types={data.paper_classification_results}/>
                </TableCell>

                <TableCell width={1.6} align={'right'} eligible={paperStatus() !== 'ELIGIBLE'}>
                    {getManuallyValidated({type: data.relevant_paper_manually_validated})}
                </TableCell>
                <TableCell width={1.6} align={'left'} eligible={paperStatus() !== 'ELIGIBLE'}>
                    {data.created_at ? format(parseISO(data.created_at), 'Pp') : ''}
                </TableCell>
                <TableCell sx={{right: 0, zIndex: 1}} eligible={paperStatus() !== 'ELIGIBLE'}>
                    <Grid container spacing={1} justifyContent={"flex-end"} alignItems="center"
                          sx={{height: '100%', width: '100%'}}>

                        <Grid item>
                            {data.provider_url === '' ?
                                <Typography sx={{fontSize: '0.875rem', color: '#656565'}}>Source</Typography> :
                                <Link href={data.provider_url} rel='noreferrer' target='_blank'
                                      sx={{fontSize: '0.875rem',}}> {t('Source')}</Link>}
                        </Grid>
                        <Grid item>
                            {data.source_url === '' ?
                                <Typography sx={{fontSize: '0.875rem', color: '#656565'}}>PDF</Typography> :
                                <CustomButton sx={{width: 'fit-content', minWidth: 'unset'}}
                                              disabled={data['paper_downloaded_path'] === ''}
                                              onClick={handleDrawerOpen}>{t('PDF')}</CustomButton>}

                        </Grid>
                        <Grid item>
                            <CopyToClipboard text={data.apa_citation}>
                                <CustomTooltip title={'Copy citations'}>
                                    <IconButton sx={{padding: '4px'}}>
                                        <ContentCopyIcon
                                            fontSize={'small'}/>
                                    </IconButton>
                                </CustomTooltip>
                            </CopyToClipboard>
                        </Grid>
                        <Grid item>
                            <CustomTooltip title={'Edit paper'}>
                                <IconButton onClick={handleEdit}><EditIcon
                                    fontSize={'small'}/></IconButton>
                            </CustomTooltip>
                        </Grid>
                        <Grid item>
                            <CustomTooltip title={'See more'}>
                                <IconButton onClick={handleClick}><MoreVertIcon/></IconButton>
                            </CustomTooltip>
                        </Grid>
                    </Grid>
                </TableCell>
                {
                    open &&
                    <ActionMenu open={open} anchorEl={anchorEl} handleClose={handleClose} handleSubmit={handleSubmit}
                                handleView={handleView}
                                handleDelete={handleDeleteOpen}
                                eligibility={paperStatus() === 'ELIGIBLE'}
                                validity={data.relevant_paper_manually_validated}/>
                }
                {paperEditingOpen &&
                    <EditPaperDialog open={paperEditing} handleClose={handleEditPaperClose} height={'30vh'}/>}
                {paperViewingOpen &&
                    <ViewPaperDrawer open={paperViewing} handleClose={handleViewPaperClose} height={'30vh'}/>}
                <>{drawerOpen && <ViewPdfDrawer open={drawerOpen} onclose={handleDrawerClose} width={'66vw'}/>}</>
                <>{paperDeleteOpen && <ConfirmationDialog open={paperDeleteOpen}
                                                          onConfirm={() => handleDelete(paperDeleting['paper_id'])}
                                                          title={`Delete paper`}
                                                          context={`Are you sure want to delete paper: ${paperDeleting['paper_name']}?`}
                                                          confirmText={'Delete'}
                                                          onClose={handleDeleteClose}/>}</>
            </>
        );
    }
;

export default PaperRow;