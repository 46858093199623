import React from 'react';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import {MenuList, Popover} from '@mui/material';
import Typography from '@mui/material/Typography';

const ActionMenu = ({
                        anchorEl,
                        open,
                        handleClose,
                        handleSubmit,
                        handleView,
                        handleDelete,
                        validity = '',
                        eligibility = true
                    }) => {

    return (
        <React.Fragment>
            <Popover id={'tag-dropdown'} open={open} anchorEl={anchorEl}
                     anchorOrigin={{
                         vertical: 'bottom',
                         horizontal: 'center',
                     }}
                     transformOrigin={{
                         vertical: 'top',
                         horizontal: 'center',
                     }}
                     onClose={handleClose}
            >
                <MenuList dense>
                    <MenuItem onClick={(e) => {
                        handleView(e)
                        handleClose()
                    }}
                              sx={{fontSize: '0.875rem'}}>
                        View details
                    </MenuItem>
                    <MenuItem onClick={(e) => {
                        handleDelete(e)
                        handleClose()
                    }}
                              sx={{fontSize: '0.875rem'}}>
                        Delete
                    </MenuItem>
                    <Divider sx={{borderBottom: '0.12em solid rgb(233, 233, 233)', margin: '2px !important'}}/>
                    <MenuItem sx={{fontSize: '0.875rem', pointerEvents: 'none', cursor: 'default'}}>
                        Manual validation
                    </MenuItem>

                    <MenuItem onClick={() => {
                        handleSubmit('RELEVANT');
                        handleClose();
                    }}
                              disabled={validity === 'RELEVANT' || !eligibility}>
                        <Typography sx={{paddingLeft: '1em', fontSize: '0.875rem'}}>Relevant</Typography>
                    </MenuItem>
                    <MenuItem onClick={() => {
                        handleSubmit('NOT_RELEVANT');
                        handleClose()
                    }}
                              disabled={validity === 'NOT_RELEVANT' || !eligibility}>
                        <Typography sx={{paddingLeft: '1em', fontSize: '0.875rem'}}>Not Relevant</Typography>
                    </MenuItem>
                    <MenuItem onClick={() => {
                        handleSubmit('NOT_EVALUATED');
                        handleClose()
                    }}
                              disabled={validity === 'NOT_EVALUATED' || !eligibility}>
                        <Typography sx={{paddingLeft: '1em', fontSize: '0.875rem'}}> Not Validated</Typography>
                    </MenuItem>
                </MenuList>
            </Popover>
        </React.Fragment>
    );
}

export default ActionMenu;