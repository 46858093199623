import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import Grid from '@mui/material/Grid';
import {Checkbox, FormControlLabel, FormGroup, FormLabel, Radio, RadioGroup, TextField} from '@mui/material';
import {Formik} from 'formik';
import FormControl from '@mui/material/FormControl';
import Typography from "@mui/material/Typography";


const ExtractEvidenceForm = ({
                                 formRef,
                                 onSubmit,
                                 edit = false,
                                 evidence,
                                 customGpts = [],
                             }) => {

    const {t} = useTranslation();
    const [selectedExtractionType, setSelectedExtractionType] = useState(['CHAT_GPT']);

    const validationSchema = Yup.object().shape({});

    const handleTypeChange = (sourceId, setFieldValue) => {
        let selected = [...selectedExtractionType]
        if (selectedExtractionType.includes(sourceId)) {
            selected = selected.filter(id => id !== sourceId)
        } else {
            if (selectedExtractionType.length < 3) {
                selected.push(sourceId)
            }
        }
        setSelectedExtractionType(selected)
        setFieldValue('extraction_types', selected);
        setFieldValue('gpt_prompt', customGpts[0]?.prompt);
    }

    const getExtractionType = (type) => {
        switch (type) {
            case "CHAT_GPT":
                return "Custom GPT"
            case "GATES":
                return "Gates"
            case "CHAT_GPT_KOR":
                return "GPT KOR (DEV Testing)"
            default:
                return ""
        }
    }

    return (
        <Grid conainer item xs={12}>
            <Formik
                initialValues={{
                    // limit_extraction_to_new_papers: edit ? evidence['limit_extraction_to_new_papers'] : false,
                    // extraction_limit: edit ? evidence['extraction_limit'] : 10,
                    gpt_prompt: edit ? evidence['gpt_prompt'] : '',
                    extraction_types: edit ? evidence['extraction_types'] : ['CHAT_GPT'],
                }}
                innerRef={formRef}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize
            >
                {({
                      handleChange,
                      values,
                      touched,
                      errors,
                      setFieldValue
                  }) => (
                    <Grid container spacing={3}>
                        <Grid item xs={12} sx={{height: 'fit-content'}} container spacing={2}>
                            {/*<Grid item xs={12}>*/}
                            {/*    <FormControl variant="outlined" fullWidth>*/}
                            {/*        <FormGroup>*/}
                            {/*            <Grid container alignItems={'center'} spacing={1}>*/}
                            {/*                <Grid item>*/}
                            {/*                    <Typography>Extract evidences from already extracted*/}
                            {/*                        papers?</Typography>*/}
                            {/*                </Grid>*/}
                            {/*                <Grid item>*/}
                            {/*                    <FormControlLabel*/}
                            {/*                        control={*/}
                            {/*                            <Checkbox checked={values.limit_extraction_to_new_papers}*/}
                            {/*                                      onChange={(e) => setFieldValue('limit_extraction_to_new_papers', e.target.checked)}*/}
                            {/*                                      name="gilad"/>*/}
                            {/*                        }*/}
                            {/*                        label={''}*/}
                            {/*                        labelPlacement={'start'}*/}
                            {/*                    />*/}
                            {/*                </Grid>*/}
                            {/*            </Grid>*/}
                            {/*        </FormGroup>*/}
                            {/*    </FormControl>*/}
                            {/*</Grid>*/}

                            <Grid item xs={12} container alignItems={'flex-start'} spacing={1}>

                                {/*<Grid item xs={4} container alignItems={'center'} spacing={1}>*/}
                                {/*    <Grid item xs={12}>*/}
                                {/*        <Typography>{t("Extraction limit")}</Typography>*/}
                                {/*    </Grid>*/}
                                {/*    <Grid item>*/}
                                {/*        <TextField id="extraction_limit"*/}
                                {/*                   required*/}
                                {/*                   name="extraction_limit"*/}
                                {/*                   value={values.extraction_limit}*/}
                                {/*                   onChange={handleChange}*/}
                                {/*                   error={Boolean(touched.extraction_limit && errors.extraction_limit)}*/}
                                {/*                   helperText={touched.extraction_limit && errors.extraction_limit}*/}
                                {/*                   type={"number"}*/}
                                {/*                   variant="outlined" size="small"/>*/}
                                {/*    </Grid>*/}
                                {/*</Grid>*/}

                                <Grid item xs={8} container justifyContent={'space-between'} alignItems={'flex-start'} spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography>{t("Extract using")}</Typography>
                                    </Grid>
                                    <Grid item sx={{paddingRight: '0.25em'}} xs={12} container>
                                        {customGpts.map(gpt => (
                                            <Grid item xs={6}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox checked={selectedExtractionType?.includes(gpt.value)}
                                                                  value={gpt.value}
                                                                  disabled={!gpt.active}
                                                                  onChange={() => handleTypeChange(gpt.value, setFieldValue, values.extraction_types)}
                                                                  name={gpt.value}/>
                                                    }
                                                    sx={{width: '100%'}}
                                                    label={getExtractionType(gpt.name)}
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                )}
            </Formik>
        </Grid>
    );
};

export default ExtractEvidenceForm;