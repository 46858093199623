import React, {useState} from 'react';
import Box from '@mui/material/Box';
import styled from '@mui/system/styled';
import MuiAccordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Grid from '@mui/material/Grid';
import {useTranslation} from 'react-i18next';
import {format, parseISO} from 'date-fns';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import CachedIcon from '@mui/icons-material/Cached';
import Divider from '@mui/material/Divider';

import useHover from 'hooks/useHover';
import CardHeaderColumn from 'components/elements/CardHeader';
import ConfirmationDialog from 'components/elements/ConfirmationDialog';
import useVerticalDelete from 'hooks/vertical/useVerticalDelete';
import CustomTooltip from 'components/elements/CustomTooltip';
import LabeledChip from "../../../../components/elements/LabeledChip";

const CardRoot = styled(Box)(() => ({
    padding: '2px 0px 6px 0px',
}));

const Accordion = styled(MuiAccordion)(({theme, active = false}) => ({
    border: '0.1em solid #E9E9E9',
    boxShadow: 'none',
    ...(active && {
        background: 'aliceblue',
    }),
}));

const ScoreSessionCard = ({style, data, index, handleRefetch}) => {
        const {t} = useTranslation();
        const [hoverRef] = useHover();

        const [expand, setExpand] = useState(false);

        const [verticalDeleting, setVerticalDeleting] = useState(null);

        const {mutateAsync, status} = useVerticalDelete();

        const handleDeleteOpen = (vertical) => {
            setVerticalDeleting(vertical)
        }

        const handleDeleteVerticalClose = () => {
            setVerticalDeleting(null)
        }

        const handleDelete = (vertical) => {
            mutateAsync({vertical}).then(() => {
                setVerticalDeleting(null);
                handleRefetch();
            })
        }

        const statusIcon = (status) => {
            switch (status) {
                case 'SUCCESS':
                    return <CheckCircleIcon sx={{color: '#00c360'}}/>;
                case 'ERROR':
                    return <CancelIcon sx={{color: '#ff0000'}}/>;
                case 'QUEUED':
                    return <PendingOutlinedIcon sx={{color: '#EED437'}}/>;
                case 'RUNNING':
                    return <CachedIcon sx={{color: '#0894d3'}}/>;
                default:
                    return <></>;
            }
        };

        const statusTooltip = (status) => {
            switch (status) {
                case 'SUCCESS':
                    return t('Success');
                case 'ERROR':
                    return t('Error');
                case 'QUEUED':
                    return t('Queued');
                case 'RUNNING':
                    return t('Running');
                default:
                    return '';
            }
        };

        const getClassificationType = (type) => {
            switch (type) {
                case 'CLASSIC_ML_MODEL':
                    return <LabeledChip label={`${t('Classic ML Model')}`}
                                        backgroundColor={'#daf7ca'}/>;
                case 'OPENAI_MODEL':
                    return <LabeledChip label={`${t('Custom GPT Model')}`}
                                        backgroundColor={'#efefcb'}/>;
                default:
                    return type
            }
        }

        return (
            <CardRoot style={style} ref={hoverRef}>
                <Accordion expanded={expand} TransitionProps={{unmountOnExit: true}}>
                    <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{
                            '&.MuiAccordionSummary-root': {boxShadow: '1px 2px 13px -10px #888'},
                            '&.MuiAccordionSummary-root.Mui-expanded': {minHeight: 'unset'},
                            div: {
                                '&.MuiAccordionSummary-content.Mui-expanded': {margin: '12px 0'},
                            },
                            '&:hover': {
                                cursor: 'pointer'
                            }
                        }}

                    >
                        <Grid container justifyContent="space-between" flexWrap='nowrap' spacing={1}>
                            <Grid container item xs={0.4} alignItems='center' justifyContent='center' sx={{height: '100%'}}>
                                <Grid item>
                                    <CustomTooltip title={statusTooltip(data['status'])}>
                                        {statusIcon(data['pipeline_run']['status'])}</CustomTooltip></Grid>

                            </Grid>
                            <Grid item xs={0.2} sx={{height: '100%'}}>
                                <Divider sx={{
                                    '&.MuiDivider-root': {
                                        borderWidth: '1px',
                                        borderColor: '#a7a7a7',
                                        width: 'fit-content'
                                    }
                                }}
                                         orientation='vertical'/>
                            </Grid>
                            <CardHeaderColumn
                                spacing={5.4}
                                headerName={t('Session name')}
                                headerTitle={<Grid
                                    item
                                    container
                                    alignItems="center"
                                >
                                    <Grid item>{data['classification_run_name']}</Grid>
                                </Grid>}
                            />
                            <CardHeaderColumn
                                spacing={2.5}
                                headerName={t('Classification type')}
                                headerTitle={getClassificationType(data['type'])}
                            />
                            <CardHeaderColumn
                                spacing={3}
                                headerName={t('Created on')}
                                headerTitle={data['created_at'] ? format(parseISO(data['created_at']), 'Pp') : 'N/A'}
                            />
                            <Grid item container spacing={0.5} justifyContent={"flex-end"} alignItems="center"
                                  sx={{height: '100%'}} xs={1.6}>
                                {/*<Grid item><IconButton onClick={(e) => {*/}
                                {/*    e.stopPropagation();*/}
                                {/*    handleDeleteOpen(data);*/}
                                {/*}}><DeleteIcon*/}
                                {/*    fontSize={'small'}/></IconButton></Grid>*/}
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                </Accordion>
                <>{verticalDeleting && <ConfirmationDialog open={verticalDeleting}
                                                           onConfirm={() => handleDelete(verticalDeleting.vertical)}
                                                           title={`Delete vertical ${verticalDeleting.vertical_name}`}
                                                           context={`Are you sure want to delete vertical ${verticalDeleting.vertical_name}?`}
                                                           confirmText={'Delete'}
                                                           onClose={handleDeleteVerticalClose}/>}</>
            </CardRoot>
        );
    }
;

export default ScoreSessionCard;